import { useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';

const DropdownList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    return (
        <>
            <button onClick={() => navigate('/support/dropdowns/upload')}>Add Dropdown</button>
            {
                loading && (
                    <div className="loadingBackground">
                        <ReactLoading
                            type="spin"
                            color="#006aff"
                            className="loadingSpin"
                        />
                    </div>
                )
            }
        </>
    );
};

export default DropdownList;