import { useContext } from "react";
import "./accDrop.css";
import { UserAuthContext } from "../../../context/UserAuthContext";
import { ReactComponent as LanguageIcon } from "../../../assets/icons/trvto_language.svg";
import { ReactComponent as CurrencyIcon } from "../../../assets/icons/trvto_currency.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/trvto_arrowRightBlack.svg";
import { Link, useNavigate } from "react-router-dom";

export default function AccDrop({ setAccountDrop }) {
  const { isAuthenticated, logout } = useContext(UserAuthContext);
  const navigate = useNavigate();

  return (
    <div className="accDropDiv">
      <div className="alignCenter" style={{ gap: '8px' }}>
        <button className="outlinedBtn"><LanguageIcon /> English</button>
        <button className="outlinedBtn"><CurrencyIcon /> ₹</button>
      </div>
      {!isAuthenticated && (
        <div className="list">
          <Link className="hideWeb">Login <ArrowRight className="arrow" /></Link>
          <Link className="hideWeb">Sign Up <ArrowRight className="arrow" /></Link>
          <Link>Accomodation <ArrowRight className="arrow" /></Link>
          <Link>Explore TrvTo <ArrowRight className="arrow" /></Link>
          {window.location.pathname !== '/' && <Link to='/org/login'>List Your Trips <ArrowRight className="arrow" /></Link>}
          <Link to='/contact-us'>Contact Us <ArrowRight className="arrow" /></Link>
          <Link>Help <ArrowRight className="arrow" /></Link>
          <Link>Profile <ArrowRight className="arrow" /></Link>
        </div>
      )}
      {isAuthenticated && (
        <>
          <button
            style={{ marginTop: '12px' }}
            type="button"
            onClick={() => {
              setAccountDrop(false);
              navigate('/contact-us')
            }}
            name="myWishlist"
            className="accDropBtn"
          >
            Contact Us
          </button>
          <button
            type="button"
            onClick={() => {
              setAccountDrop(false);
              navigate('/my-wishlist')
            }}
            name="myWishlist"
            className="accDropBtn"
          >
            My Wishlist
          </button>
          <button
            type="button"
            onClick={() => {
              setAccountDrop(false);
              navigate('/my-booking')
            }}
            name="mybooking"
            className="accDropBtn"
          >
            My Booking
          </button>
          <button
            type="button"
            onClick={() => {
              setAccountDrop(false);
              logout();
            }}
            name="mybooking"
            className="accDropBtn"
          >
            Logout
          </button>
        </>
      )}
    </div>
  );
}
