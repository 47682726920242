import { useEffect, useState } from 'react';
import BlogCard from '../../../components/blogCard/BlogCard';
import MobileCarousel from '../../../components/mobileCarousel/mobileCarousel';
import fetchBlogsData from '../../blog/fetchBlogsFun/fetchBlogsData';
import './blog.css';
import { useNavigate } from 'react-router-dom';

const BlogSection = () => {
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        fetchBlogsData(setBlogData, setLoading);
    }, []);

    return (
        <div className="blogSection customContainer" id="blogSection">
            <div className="alignCenter mobCenter">
                <div>
                    <p className='pageCount'>06 Blog</p>
                    <p className="homeTitle">Our Latest Blogs</p>
                </div>
                <button onClick={() => navigate('/blog')} className="readMoreBtn mobHidden viewAll">View All</button>
            </div>
            <div className="blogCards mobHidden">
                {blogData.slice(0, 3).map((blog, blogIndex) => {
                    return <BlogCard blogData={blog} key={blogIndex} />;
                })}
            </div>
            <div className="mobDisplay">
                <MobileCarousel type='blog' data={blogData} />
            </div>
        </div>
    );
}

export default BlogSection;
