const BlogCard = ({ data }) => {
    return (
        <>
            {data.map((item, index) => (
                <div key={index} className="blogCard">
                    <img alt="trvto_blog" src={item.blogImage} className="blogImage" />
                    <div className="blogCardTextDiv">
                        <h3 className="blogCardName">{item.blogName}</h3>
                        <p className="blogCardDis">{item.blogShortDis}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default BlogCard;
