import { useContext, useState } from 'react';
// import datesta from '../../../dummyData/orgDates.json';
// import datestaPrv from '../../../dummyData/orgDatesPrv.json';
import './dates.css';
import ReactLoading from 'react-loading';
import errorHandle from '../../../utils/errorHandle';
import { useNavigate } from 'react-router-dom';
import { OrgDatesContext } from '../../../context/OrgDatesContest';
import { tripDateDelete, tripOrgDateFun } from '../../../api/orgTripAPI';
import DeleteIcon from '../../../assets/icons/trvto_deleteIcon.svg';
import Dialog from '../../dialogModal/dialogModal';
import { toast } from 'react-toastify';

export default function Dates({ id }) {
    const navigate = useNavigate();
    // const [upComing, setUpComing] = useState(true);
    const [loading, setLoading] = useState(false);
    // const [dates, setDates] = useState([]);
    const [rowClickId, setRowClickId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    const { dates, setDates, upComing, setUpComing } =
        useContext(OrgDatesContext);
    const packId = window.location.pathname.split('/')[4];

    async function buttonClick(which) {
        if (which === 'upComing' && upComing === true) {
            return;
        }
        if (which === 'completed' && upComing === false) {
            return;
        }
        if (which === 'upComing') {
            setLoading(true);
            setUpComing(!upComing);
            try {
                const dateData = await tripOrgDateFun(packId, 'next');
                setDates(dateData.data);
                // setDates(datesta.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                errorHandle(error);
            }
        } else if (which === 'completed') {
            setLoading(true);
            setUpComing(!upComing);
            try {
                const dateData = await tripOrgDateFun(packId, 'previous');
                setDates(dateData.data);
                // setDates(datestaPrv.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                errorHandle(error);
            }
        }
    }

    const deleteDate = async () => {
        try {
            setLoading(true);
            const response = await tripDateDelete(rowClickId);
            if (response.status === 200) {
                setUpComing(!upComing);
                buttonClick('upComing');
                setLoading(false);
                toast.success('Date deleted successfully');
                setDeleteModal(false);
            }
            console.log(response);
        } catch (err) {
            setLoading(false);
            errorHandle(err);
        }
    };

    const datesRowClick = (id) => {
        if (id !== 0) {
            navigate(`/org/trip/date/${id}`);
        }
    };

    const handleRowClick = (row) => {
        if (row.bookedSlots === 0) {
            toast.error('Please select row which has booked slots');
        } else {
            datesRowClick(row._id);
        }
    };

    // useEffect(() => {
    //   setLoading(true);
    //   try {
    //     // const dateData = await tripOrgDateFun(id, "next");
    //     // setDates(dateData.data.data);
    //     setDates(datesta.data);
    //     setLoading(false);
    //   } catch (error) {
    //     setLoading(false);
    //     errorHandle(error);
    //   }
    // }, []);

    return (
        <div>
            <div className="orgDateTabBtnDiv">
                <button
                    className={upComing ? 'orgDateTabUpCo' : 'orgDateTabCom'}
                    onClick={() => buttonClick('upComing')}
                >
                    Upcoming
                </button>
                <button
                    className={upComing ? 'orgDateTabCom' : 'orgDateTabUpCo'}
                    onClick={() => buttonClick('completed')}
                >
                    Completed
                </button>
            </div>
            {loading ? (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            ) : (
                <table className="orgDatesTabTable">
                    <thead className="orgDatesTabHead">
                        <tr>
                            <th>Start Date</th>
                            <th>Total Slots</th>
                            <th>Booked Solts</th>
                            {upComing && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {dates.map((row, rowKey) => {
                            return (
                                <tr
                                    key={rowKey}
                                    className="orgDatesTabRow"
                                    onClick={() => handleRowClick(row)}
                                >
                                    <td>{row.startDate.split('T')[0]}</td>
                                    <td>{row.totalSlots}</td>
                                    <td>{row.bookedSlots}</td>
                                    {upComing && (
                                        <td>
                                            {row.bookedSlots === 0 && (
                                                <button
                                                    className="deleteBtn"
                                                    onClick={() => {
                                                        setDeleteModal(true);
                                                        setRowClickId(row._id);
                                                    }}
                                                >
                                                    <img
                                                        src={DeleteIcon}
                                                        alt="trvto_delete"
                                                    />
                                                </button>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
            {deleteModal && (
                <Dialog
                    openModal={deleteModal}
                    heading={'Delete Confirmation!'}
                >
                    <div className="dialogContent">
                        <p className="confirmationText">
                            Are you sure? You want to delete this date
                        </p>
                        <div className="alignCenter">
                            <button
                                className="dialogActBtn submit"
                                onClick={deleteDate}
                            >
                                Yes
                            </button>
                            <button
                                className="dialogActBtn cancel"
                                onClick={() => {
                                    setDeleteModal(false);
                                    setRowClickId(0);
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    );
}
