import { useState } from "react";
import DirectInput from "../../../components/directInput/DirectInput";
import { addOffersSubmit } from "../addOffersFun/addOffersSubmit";
import ReactLoading from 'react-loading';

const OffersUpload = () => {
    const [loading, setLoading] = useState(false);
    const [offersData, setOffersData] = useState({
        name: '',
        code: '',
        percentage: 0,
        min: 0,
        minPeople: 0,
        limit: 0,
        people: 0
    });

    return (
        <div>
            <h1 className="orgPackUpH1">Create New Offer</h1>
            <div className="orgPackUpSecDiv">
                <DirectInput
                    label="Name"
                    name="name"
                    value={offersData?.name}
                    type="text"
                    placeholder="Name"
                    setFormData={setOffersData}
                    min={3}
                    limit={50}
                />
                <DirectInput
                    label="Code"
                    name="code"
                    value={offersData?.code}
                    type="text"
                    placeholder="Code"
                    setFormData={setOffersData}
                    min={3}
                    limit={10}
                />
                <DirectInput
                    label="Percentage"
                    name="percentage"
                    value={offersData?.percentage}
                    type="number"
                    placeholder="Percentage"
                    setFormData={setOffersData}
                    min={0}
                    limit={50}
                />
                <DirectInput
                    label="Min"
                    name="min"
                    value={offersData?.min}
                    type="number"
                    placeholder="Min"
                    setFormData={setOffersData}
                />
                <DirectInput
                    label="Min People"
                    name="minPeople"
                    value={offersData?.minPeople}
                    type="number"
                    placeholder="Min People"
                    setFormData={setOffersData}
                />
                <DirectInput
                    label="Limit"
                    name="limit"
                    value={offersData?.limit}
                    type="number"
                    placeholder="Limit"
                    setFormData={setOffersData}
                />
                <DirectInput
                    label="People"
                    name="people"
                    value={offersData?.people}
                    type="number"
                    placeholder="People"
                    setFormData={setOffersData}
                />
                {loading && (
                    <div className="loadingBackground">
                        <ReactLoading
                            type="spin"
                            color="#006aff"
                            className="loadingSpin"
                        />
                    </div>
                )}

                <button
                    type="button"
                    className="orgPackUpSubmitBtn blueBtn"
                    onClick={() =>
                        addOffersSubmit(offersData, setLoading)
                    }
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default OffersUpload;
