import { useState } from 'react';
import './tags.css';

export default function Tags({ data, setData }) {
    const [tag, setTag] = useState('');
    function addTag() {
        if (tag === '') {
            alert('Please enter the text');
        } else {
            setData(function (prevState) {
                return { ...prevState, tags: [...prevState.tags, tag] };
            });
            setTag('');
        }
    }
    function removeTag(text) {
        const tempArray = data.tags;
        let index = tempArray.indexOf(text);
        tempArray.splice(index, 1);
        setData(function (prevState) {
            return { ...prevState, tags: [...tempArray] };
        });
    }
    return (
        <div>
            <h2 className="orgPackUpH2">Tags</h2>
            <div className="orgPackUpSecDiv">
                <div>
                    <p className="tagsNoteP">
                        Note: Tags are used for search support, You need to add
                        one of the tag as organizer name complsorily. Add all
                        the other relevant words which people might use to
                        search your package. You can add up to 30 tags with max
                        length 30 letters each.
                    </p>
                </div>
                <div className="itineraryInDiv">
                    <label className="itineraryLabel">Tags</label>
                    <div className="itineraryInputDiv">
                        <input
                            type="text"
                            onChange={(e) => setTag(e.target.value)}
                            placeholder="Enter and press Add Tag to add"
                            value={tag}
                            className="nestedTagInput"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    addTag();
                                }
                            }}
                        ></input>
                        <button
                            type="button"
                            onClick={addTag}
                            className="nestedTagOkBtn blueBtn"
                        >
                            Add Tag
                        </button>
                    </div>
                </div>
                {data.tags.length > 0 && (
                    <div className="tagContainer">
                        <div className="tagOutDiv">
                            {data.tags.map((text, index) => {
                                return (
                                    <div key={index} className="tagDiv">
                                        <p className="tagP">{text}</p>
                                        <button
                                            className="tagButton"
                                            onClick={(e) => removeTag(text)}
                                        >
                                            X
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
