import { getOfferDetailsFun } from '../../../../api/infoAPI';
import errorHandle from '../../../../utils/errorHandle';

export default async function offerApplyTrip(
  offerCode,
  setOfferData,
  setBooking
) {
  try {
    const checkOffer = await getOfferDetailsFun(offerCode);
    setOfferData(checkOffer.data);
    setBooking((prevState) => {
      return { ...prevState, offerCode: offerCode };
    });
  } catch (error) {
    errorHandle(error);
  }
}
