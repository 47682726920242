import { listOffers } from "../../../api/superadmin";
import errorHandle from "../../../utils/errorHandle";

export const fetchOffers = async (setLoading, setOffersData, limit, offset) => {
    setLoading(true);
    try {
        const offers = await listOffers(limit, offset);
        console.log(offers);
        setLoading(false);
    } catch (error) {
        errorHandle(error);
        setLoading(false);
    }
};
