import { useState } from "react";
import Tab from "../../components/tabs/tabs";
import "./tabsBar.css";
import Holiday from "./searchComponents/holiday";
import Camp from "./searchComponents/camp";

export default function TabsBar({ className }) {
  const [activeTab, setActiveTab] = useState('Holiday');


  const handleType = (e) => {
    setActiveTab(e.target.value);
  };

  return (
    <div className={`homeSearchWrapper ${className}`}>
      <div className="tabsWrapper">
        <Tab label='Holiday' activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab disabled label='Camp' activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab disabled label='Guide' activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="tabContent">
        {activeTab === 'Holiday' && <Holiday />}
        {activeTab === 'Camp' && <Camp />}
        {activeTab === 'Guide' && <Holiday />}
      </div>
      <div className="searchCard">
        <select
          value={activeTab}
          name="type"
          onChange={(e) => handleType(e)}
          className="homeSearch"
        >
          <option value="Select">Select</option>
          <option value="Holiday">Holiday</option>
          <option value="Camp" disabled>
            Camp
          </option>
          <option value="Guide" disabled>
            Guide
          </option>
        </select>
        {(activeTab === 'Holiday' ||
          activeTab === 'Guide') && <Holiday />}
        {activeTab === 'Camp' && <Camp />}
      </div>
    </div>
  );
}
