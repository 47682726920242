import { toast } from "react-toastify";
import { dropdownUploadFun } from "../../../api/addDataAPI";
import errorHandle from "../../../utils/errorHandle";

export const addDropdownSubmit = async (dropdownData, setLoading) => {
    try {
        await dropdownUploadFun(dropdownData);
        toast.success('Dropdown created successfully');
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};