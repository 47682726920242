import { useState } from "react";
import DirectInput from "../../../components/directInput/DirectInput";
import ReactLoading from 'react-loading';
import './addDropdown.css';
import Dropdown from "../../../components/dropdown/Dropdown";
import { addDropdownSubmit } from "../addDropdownFun/addDropdown";

const DropdownUpload = () => {
    const [loading, setLoading] = useState(false);
    const [dropdownData, setDropdownData] = useState({
        type: '',
        heading: '',
        dropdown: [{ name: "", displayName: "" }]
    });

    const handleAddField = () => {
        setDropdownData((prev) => ({
            ...prev,
            dropdown: [...prev.dropdown, { name: "", displayName: "" }],
        }));
    };

    const handleRemoveField = (index) => {
        setDropdownData((prev) => ({
            ...prev,
            dropdown: prev.dropdown.filter((_, i) => i !== index),
        }));
    };

    const handleInputChange = (index, field, value) => {
        setDropdownData((prev) => ({
            ...prev,
            dropdown: prev.dropdown.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            ),
        }));
    };

    const dropdownDataSubmit = () => {
        addDropdownSubmit(dropdownData, setLoading);
    };

    const handleType = (e) => {
        const value = e.target.value;
        setDropdownData((prev) => ({ ...prev, type: value }));
    };

    return (
        <div>
            <h1 className="orgPackUpH1">Create New Dropdown</h1>
            <div className="orgPackUpSecDiv">
                <div className="directInDiv">
                    <label className="directInLabel">Type</label>
                    <select
                        value={dropdownData.type}
                        name="type"
                        onChange={handleType}
                        className="supportSelect"
                    >
                        <option value="Select">Select</option>
                        <option value="trip">Trip</option>
                        <option value="camp">Camp</option>
                        <option value="guide">Guide</option>
                    </select>
                </div>
                <DirectInput
                    label="Heading"
                    name="heading"
                    value={dropdownData?.heading}
                    type="text"
                    placeholder="Heading"
                    setFormData={setDropdownData}
                    min={3}
                    limit={10}
                />
                <div className="dropdownSubCon">
                    {dropdownData.dropdown.map((item, index) => (
                        <div key={index} className="dropdownFieldRow">
                            <div className="inputGroup">
                                <label className="directInLabel">Name</label>
                                <input
                                    type="text"
                                    name={`name-${index}`}
                                    value={item.name}
                                    placeholder="Name"
                                    onChange={(e) =>
                                        handleInputChange(index, "name", e.target.value)
                                    }
                                />
                            </div>
                            <div className="inputGroup">
                                <label className="directInLabel">Display Name</label>
                                <input
                                    type="text"
                                    name={`displayName-${index}`}
                                    value={item.displayName}
                                    placeholder="Display Name"
                                    onChange={(e) =>
                                        handleInputChange(index, "displayName", e.target.value)
                                    }
                                />
                            </div>
                            <button
                                type="button"
                                onClick={() => handleRemoveField(index)}
                                className="removeBtn btnStyle"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddField}
                        className="addBtn btnStyle"
                    >
                        Add
                    </button>
                </div>
                {loading && (
                    <div className="loadingBackground">
                        <ReactLoading
                            type="spin"
                            color="#006aff"
                            className="loadingSpin"
                        />
                    </div>
                )}

                <button
                    type="button"
                    className="orgPackUpSubmitBtn blueBtn"
                    onClick={() => dropdownDataSubmit()}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default DropdownUpload;
