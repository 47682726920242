import { useEffect, useState } from "react";
import TripStrap from "../../components/tripStrap/TripStrap";
import "./myBooking.css";
import ReactLoading from "react-loading";
import TitleBar from "../../components/titleBar/titleBar";
import myBookingData from "./myBookingFun/myBookingData";
import EmptyPage from "../../components/empty-page/emptyPage";

export default function MyBooking() {
  const type = [
    { name: "Trip", value: "trip" },
    // { name: "Ayurveda", value: "ayur" },
    // { name: "Guid", value: "guid" },
    // { name: "Camping", value: "camp" },
  ];
  const [bookingData, setBookingData] = useState([]);
  const [typeSel, setTypeSel] = useState("trip");
  const [loading, setLoading] = useState(false);
  const [nav, setNav] = useState(false);

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        await myBookingData(typeSel, setBookingData, setLoading);
      } catch (err) {
        console.log('Failed to fetch search results');
      }
    };

    fetchBookingData();
  }, [typeSel]);

  console.log(bookingData);

  return (
    <div>
      {loading && (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#006aff" className="loadingSpin" />
        </div>
      )}
      <div>
        {/* discuss on whether we need filters in mybooking */}
        {/* <Filters filterData={filterDummy?.data} /> */}
        {/* No filters for this version */}
        {/* <Filters filterData={[]} /> */}
        <div>
          {/* <div>
            <h1 className="myBookingH1">My Bookings</h1>
            <select
              className="myBookingSelect"
              value={typeSel}
              onChange={(e) => handleTextChange(e, setTypeSel)}
            >
              {type.map(function (obj, objIndex) {
                return (
                  <option value={obj.value} key={objIndex}>
                    {obj.name}
                  </option>
                );
              })}
            </select>
          </div> */}

          <TitleBar title="My Bookings" />
          <div className="customContainer">
            {bookingData?.length === 0 && !loading ? (
              <EmptyPage />
            ) : (
              bookingData?.map((item, index) => (
                <TripStrap
                  key={index}
                  page={"bookings"}
                  setNav={setNav}
                  data={item}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
