import { toast } from 'react-toastify';
import { createTicketFun, supInfoFun } from '../../../api/supportAPI';
import errorHandle from '../../../utils/errorHandle';

export const getSupportInfo = (type, phoneNumber, setTableData, setLoading) => {
    setLoading(true);
    try {
        if (!type) {
            toast.error('Please select search type');
            setLoading(false);
        } else if (!phoneNumber) {
            toast.error('Please enter phone number to search');
            setLoading(false);
        } else {
            supInfoFun(type, phoneNumber).then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    setTableData(res.data);
                    setLoading(false);
                } else {
                    toast.error('Please enter valid phone number');
                    setLoading(false);
                }
            });
        }
    } catch (err) {
        setLoading(false);
        errorHandle(err);
    }
};

export const submitTicket = (type, data, setLoading) => {
    setLoading(true);
    try {
        if (data.description === '') {
            toast.error('Please enter comment');
            setLoading(false);
        } else {
            createTicketFun(type, data).then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    toast.success('Ticket created successfully');
                }
                setLoading(false);
            });
        }
    } catch (err) {
        setLoading(false);
        errorHandle(err);
    }
};
