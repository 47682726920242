import "./blog.css";
import EmptyPage from "../../components/empty-page/emptyPage";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import fetchBlogsData from "./fetchBlogsFun/fetchBlogsData";
import deleteBlogsData from "./deleteBlogFun/deleteBlog";
import { useNavigate } from "react-router-dom";

export default function BlogList() {
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        fetchBlogsData(setBlogData, setLoading);
    }, []);

    const tableHeader = [
        'Name',
        'Short Description',
        'Author',
        'Action'
    ];

    const handleDelete = async (row) => {
        await deleteBlogsData(row._id, setLoading);
        fetchBlogsData(setBlogData, setLoading);
    };

    const handleEdit = async (row) => {
        navigate('/support/blog/upload', { state: { blogData: row } });
    };

    return (
        <div>
            <div className="tableContainer">
                {blogData?.length === 0 ? (
                    <EmptyPage />
                ) : (
                    <>
                        <button onClick={() => navigate('/support/blog/upload')}>Add Blog</button>
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {blogData?.map((row, rowIndex) => {
                                    return (
                                        <tr
                                            key={rowIndex}
                                            className="tableBodyRow"
                                        >
                                            <td>{row.name}</td>
                                            <td>{row.shortDescription}</td>
                                            <td>{row.author}</td>
                                            <td>
                                                <div className="btnContainer">
                                                    <button className="btnEdit" onClick={() => handleEdit(row)}>Edit</button>
                                                    <button className="btnDelete" onClick={() => handleDelete(row)}>Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="responsiveCardContainer">
                            {blogData?.map((row, rowIndex) => (
                                <div key={rowIndex} className='responsiveCard'>
                                    <div className="cardContent">
                                        <p><strong>{tableHeader[0]}</strong> {row.name}</p>
                                        <p><strong>{tableHeader[1]}</strong> {row.shortDescription}</p>
                                        <p><strong>{tableHeader[1]}</strong> {row.author}</p>
                                        <div className="btnContainer">
                                            <button className="btnEdit" onClick={() => handleEdit(row)}>Edit</button>
                                            <button className="btnDelete" onClick={() => handleDelete(row)}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading type="spin" color="#006aff" className="loadingSpin" />
                </div>
            )}
        </div>
    );
}
