import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { razorPayKey } from '../../config/razorPay';

const Payment = forwardRef(({ options: userOptions }, ref) => {
    const paymentHandler = (options) => {
        var rzp = new window.Razorpay(options);
        rzp.open();
    };

    useImperativeHandle(ref, () => ({
        initiatePayment: () => {
            const options = {
                key: razorPayKey,
                currency: 'INR',
                amount: userOptions.amount * 100,
                name: userOptions.name,
                description: userOptions.description,
                image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
                // order_id: userOptions.order_id,
                handler: function (response) {
                    alert('Payment ID: ' + response.razorpay_payment_id);
                },
                prefill: {
                    name: userOptions.prefill.name,
                    email: userOptions.prefill.email,
                    contact: userOptions.prefill.contact
                }
            };
            paymentHandler(options);
        }
    }));

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    });

    return null;
});

export default Payment;
