import './reviewCarousel.css';
import { ReactComponent as LeftInActive } from '../../assets/home/trvto_leftInActive.svg';
import { ReactComponent as RightActive } from '../../assets/home/trvto_RightActive.svg';
import { useRef } from "react";
import ReviewCard from './ReviewCard';

const ReviewCarousel = ({ data }) => {
    const carouselRef = useRef(null);

    const scrollLeft = () => {
        if (carouselRef.current) {
            const slideWidth = carouselRef.current.scrollWidth / carouselRef.current.children.length;
            carouselRef.current.scrollBy({
                left: -slideWidth,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            const slideWidth = carouselRef.current.scrollWidth / carouselRef.current.children.length;
            carouselRef.current.scrollBy({
                left: slideWidth,
                behavior: 'smooth',
            });
        }
    };
    return (
        <>
            <div className="reviewCarouselBtnWrapper">
                <button className="carouselBtn" onClick={scrollLeft}><LeftInActive /></button>
                <button className="carouselBtn active" onClick={scrollRight}><RightActive /></button>
            </div>
            <div className="review-carousel-container" ref={carouselRef}>
                {data.map((item, index) => {
                    return <ReviewCard key={index} data={item} />
                })}
            </div>
        </>
    );
};

export default ReviewCarousel;
