import { useEffect, useState } from 'react';
import Pagination from '../../components/pagination/Pagination';
import './orgBooking.css';
import {
    fetchOrgBooking,
    getBookingsToday,
    getTrips
} from './orgBookingFun/fetchOrgBookings';
import ReactLoading from 'react-loading';
import EmptyPage from '../../components/empty-page/emptyPage';
import fetchTripDates from './orgBookingFun/fetchTripDates';
import Dialog from '../../components/dialogModal/dialogModal';
import { sendMessage } from './orgBookingFun/sendMessage';
import CharCount from '../../components/charCount/CharCount';

const OrgBooking = () => {
    const [bookings, setBookings] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [todaysBookings, setTodaysBookings] = useState('');
    const [trips, setTrips] = useState([]);
    const [selectedType, setSelectedType] = useState('upcoming');
    const [selectedTrip, setSelectedTrip] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [tripDates, setTripDates] = useState([]);
    const [modal, setModal] = useState(false);
    const [messageType, setMessageType] = useState([]);
    const [messageData, setMessageData] = useState({
        msgType: '',
        whatsApp: '',
        message: ''
    });
    const totalData = bookings.length - 1;
    const tableHeader = [
        'Trip Name',
        'Booked  by',
        'Start City',
        'Pickup Location',
        'Transport',
        'Seats',
        'Booked On',
        'Booked Via',
        'Action'
    ];

    const dummyMessageType = [
        { value: 'whatsApp', name: 'WhatsApp Group Link' },
        { value: 'rate', name: 'Rate' },
        { value: 'custom', name: 'Custom' }
    ];

    useEffect(() => {
        const fetchBookingData = async () => {
            fetchOrgBooking(
                setBookings,
                setLoading,
                offSet,
                selectedTrip,
                selectedDate
            );
            getBookingsToday(setTodaysBookings, setLoading);
            getTrips(setTrips, setLoading);
        };
        fetchBookingData();
        setMessageType(dummyMessageType);
    }, [selectedTrip, selectedDate]);

    useEffect(() => {
        if (!selectedTrip) return;
        fetchTripDates(selectedTrip, setTripDates, selectedType, setLoading);
        //eslint-disable-next-line
    }, [selectedType, selectedTrip]);

    const paginationClick = (number, offSetNumber) => {
        if (Math.ceil(totalData / 10) > 1) {
            setPageNumber(+number);
            setOffSet(+offSetNumber);
        }
    };

    const getTripDetails = (row) => {
        return (
            <div className="bookingTitleArea">
                <div>{row.packId.title}</div>
                <div>
                    {row.startDate.split('T')[0]} (
                    {`${row.packId.days}D/${row.packId.nights}N`})
                </div>
            </div>
        );
    };

    const getUserDetails = (row) => {
        return (
            <div>
                <div>
                    {row.userDetails[0].name} ({row.userDetails[0].sex} -{' '}
                    {row.userDetails[0].age})
                </div>
                <div>{row.userDetails[0].phoneNumber}</div>
            </div>
        );
    };

    console.log(messageData);
    return (
        <>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="alignCenter">
                <h2 className="orgBookingHeading">Bookings</h2>
                <div className="orgFilterWrapper">
                    <div className="orgTripDateInfo mb-2">
                        <span className="orgTripDateSpan">Bookings Today</span>
                        <span className="orgTripDateSpan">
                            {todaysBookings.bookedToday}
                        </span>
                    </div>
                </div>
            </div>
            <div className="orgBookingFilters">
                <select
                    name="type"
                    id="type"
                    className="select"
                    onChange={(e) => setSelectedType(e.target.value)}
                >
                    <option value="upcoming">Upcoming</option>
                    <option value="completed">Completed</option>
                </select>
                {selectedType && (
                    <select
                        name="trip"
                        id="trip"
                        className="select"
                        onChange={(e) => setSelectedTrip(e.target.value)}
                    >
                        <option value="">Select Trip</option>
                        {trips.map((trip) => (
                            <option value={trip._id} key={trip._id}>
                                {trip.title}
                            </option>
                        ))}
                    </select>
                )}
                {selectedType && selectedTrip && (
                    <select
                        name="date"
                        id="date"
                        className="select"
                        onChange={(e) => setSelectedDate(e.target.value)}
                    >
                        <option value="">Select Date</option>
                        {tripDates.map((date) => (
                            <option value={date._id} key={date._id}>
                                {date.startDate.split('T')[0]}
                            </option>
                        ))}
                    </select>
                )}
            </div>
            <div className="orgBookingContainer">
                {bookings?.length === 0 && !loading ? (
                    <EmptyPage />
                ) : (
                    <div className="tableContainer">
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {bookings?.map((row, rowIndex) => {
                                    return (
                                        <tr
                                            key={rowIndex}
                                            className="tableBodyRow"
                                        >
                                            <td>{getTripDetails(row)}</td>
                                            <td>{getUserDetails(row)}</td>
                                            <td>{row.stratCity}</td>
                                            <td>{row.pickupLocation}</td>
                                            <td>
                                                {row.transport ===
                                                'With transport'
                                                    ? 'Included'
                                                    : 'Excluded'}
                                            </td>
                                            <td>{row.totalSeats}</td>
                                            <td>
                                                {row.createdAt.split('T')[0]}
                                            </td>
                                            <td>{row.bookedVia}</td>
                                            <td>
                                                <button
                                                    className="actBtn payoutBtn noWidth"
                                                    onClick={() =>
                                                        setModal(true)
                                                    }
                                                >
                                                    Send Message
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="responsiveCardContainer">
                            {bookings?.map((row, rowIndex) => (
                                <div key={rowIndex} className="responsiveCard">
                                    <div className="cardContent">
                                        <p>
                                            <strong>{tableHeader[0]}:</strong>{' '}
                                            {getTripDetails(row)}
                                        </p>
                                        <p className="bookingCardLabelDisplay">
                                            <strong>{tableHeader[1]}:</strong>{' '}
                                            {getUserDetails(row)}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[2]}:</strong>{' '}
                                            {row.stratCity}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[3]}:</strong>{' '}
                                            {row.pickupLocation}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[4]}:</strong>{' '}
                                            {row.transport === 'With transport'
                                                ? 'Included'
                                                : 'Excluded'}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[5]}:</strong>{' '}
                                            {row.totalSeats}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[6]}:</strong>{' '}
                                            {row.createdAt.split('T')[0]}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[7]}:</strong>{' '}
                                            {row.bookedVia}
                                        </p>
                                        <div className="sendMessageBtn">
                                            <button
                                                className="actBtn payoutBtn noWidth"
                                                onClick={() => setModal(true)}
                                            >
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {bookings.length > 10 && (
                    <Pagination
                        paginationClick={paginationClick}
                        numberOfRows={totalData}
                        offSet={offSet}
                        pageNumber={pageNumber}
                    />
                )}
                {modal && (
                    <Dialog openModal={modal} heading={'Send Message'}>
                        <div className="dialogContent">
                            <select
                                name="date"
                                id="date"
                                className="select w-full"
                                onChange={(e) =>
                                    setMessageData({
                                        ...messageData,
                                        msgType: e.target.value
                                    })
                                }
                            >
                                <option value="">Select Message Type</option>
                                {messageType.map((type) => (
                                    <option value={type.value} key={type.value}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                            {messageData.msgType === 'whatsApp' && (
                                <input
                                    placeholder="Whatsapp group link"
                                    name="whatsapp"
                                    onChange={(e) =>
                                        setMessageData({
                                            ...messageData,
                                            whatsApp: e.target.value
                                        })
                                    }
                                    value={messageData.whatsApp}
                                    className="whatsAppLink"
                                />
                            )}
                            {messageData.msgType !== 'rate' &&
                                messageData.msgType !== 'whatsApp' && (
                                    <>
                                        <textarea
                                            className="messageArea"
                                            value={messageData.message}
                                            onChange={(e) =>
                                                setMessageData({
                                                    ...messageData,
                                                    message: e.target.value
                                                })
                                            }
                                        />
                                        <CharCount
                                            string={
                                                messageData.message
                                                    ? messageData.message
                                                    : ''
                                            }
                                            min={10}
                                            limit={50}
                                        />
                                    </>
                                )}
                            <div className="alignCenter">
                                <button
                                    className="dialogActBtn cancel"
                                    onClick={() => setModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="dialogActBtn submit"
                                    onClick={() =>
                                        sendMessage(messageData, setLoading)
                                    }
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    );
};

export default OrgBooking;
