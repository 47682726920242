import { useState } from 'react';
import './orgRegister.css';
import DirectInput from '../../components/directInput/DirectInput';
import Owner from './orgRegSections/owner/Owner';
import CompanyDetails from './orgRegSections/company/CompanyDetails';
import ContactPerson from './orgRegSections/contactPerson/ContactPerson';
import CompanyAddress from './orgRegSections/address/CompanyAddress';
import BankCompany from './orgRegSections/bank/BankCompany';
import { Link } from 'react-router-dom';
import orgRegSubmit from './orgRegFun/orgRegSubmit';
import ReactLoading from 'react-loading';
import RegOtp from './orgRegSections/otp/RegOtp';

export default function OrgRegister() {
    const typeDrop = ['Trip', 'Guide', 'Camp'];

    const [formData, setFormData] = useState({
        type: 'Select',
        typeTemp: 'Select',
        companyName: '',
        owner: [
            {
                name: '',
                designation: '',
                phoneNumber: '',
                countryCode: '',
                emailId: '',
                identityProof: '',
                identityProofNum: ''
            }
        ],
        address: {
            name: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            pincode: '',
            state: '',
            country: ''
        },
        companyType: 'Select',
        about: '',
        logo: '',
        gst: '',
        cin: '',
        countryCode: '',
        //This phone will used to login and access the account. this phone will be used for all the phone communications
        phoneNumber: '',
        phoneOTP: '',
        //This emailID will used to login and access the account. this email will be used for all the email communications
        emailId: '',
        emailOTP: '',
        // password: '',

        contactPerson: {
            name: '',
            phoneNumber: '',
            countryCode: '',
            emailId: ''
        },
        bank: {
            bankName: '',
            accName: '',
            branchName: '',
            accNum: '',
            ifsc: ''
        }
    });
    const [loading, setLoading] = useState(false);
    const [otpStatus, setOTPStatus] = useState(false);
    const [logoData, setLogoData] = useState();
    // const [conPass, setConPass] = useState('');
    const [accRe, setAccRe] = useState('');

    function handleTypeDrop(e) {
        if (e.target.value === 'Trip') {
            setFormData((prevState) => {
                return { ...prevState, type: 'trip', typeTemp: 'Trip' };
            });
        }
        if (e.target.value === 'Guide') {
            setFormData((prevState) => {
                return { ...prevState, type: 'guid', typeTemp: 'Guide' };
            });
        }
        if (e.target.value === 'Camp') {
            setFormData((prevState) => {
                return { ...prevState, type: 'camp', typeTemp: 'Camp' };
            });
        }
    }
    return (
        <div className="orgRegisterDiv">
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <h1 className="orgRegisterH1">Organizer Register</h1>
            {/* Information of organizer to be filled */}
            <div>
                <div className="orgRegisterSecDiv">
                    <div className="dropRegDiv">
                        <label className="dropRegLabel">Type</label>
                        <div className="dropRegInputDiv">
                            <select
                                value={formData.typeTemp}
                                name="type"
                                onChange={(e) => handleTypeDrop(e)}
                                required={true}
                                className="dropRegSelect"
                            >
                                <option value="-1">Select</option>
                                {typeDrop.map(function (val, typeIndex) {
                                    return (
                                        <option key={typeIndex} value={val}>
                                            {val}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <DirectInput
                        label="Company Name"
                        name="companyName"
                        value={formData.companyName}
                        setFormData={setFormData}
                        type="text"
                        placeholder="Name of the company"
                        min={3}
                        limit={70}
                    />
                </div>
                <Owner formData={formData} setFormData={setFormData} />
                <CompanyDetails
                    formData={formData}
                    setFormData={setFormData}
                    logoData={logoData}
                    setLogoData={setLogoData}
                    // conPass={conPass}
                    // setConPass={setConPass}
                />
                <CompanyAddress formData={formData} setFormData={setFormData} />
                <ContactPerson formData={formData} setFormData={setFormData} />
                <BankCompany
                    formData={formData}
                    setFormData={setFormData}
                    accRe={accRe}
                    setAccRe={setAccRe}
                />
                <RegOtp
                    formData={formData}
                    setFormData={setFormData}
                    setLoading={setLoading}
                    otpStatus={otpStatus}
                    setOTPStatus={setOTPStatus}
                />
            </div>
            <p className="orgRegisterTerm">
                By Clicking on Register you accept our{' '}
                <Link to="/org/terms">Terms and Condition</Link>,{' '}
                <Link to="/org/privacy">Privacy Policy</Link>
            </p>
            <button
                type="button"
                className="orgRegisterSubmitBtn blueBtn"
                onClick={() =>
                    orgRegSubmit(formData, setLoading, accRe, logoData)
                }
            >
                Register
            </button>
        </div>
    );
}
