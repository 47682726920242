import { toast } from "react-toastify";
import { offerUploadFun } from "../../../api/addDataAPI";
import errorHandle from "../../../utils/errorHandle";

export const addOffersSubmit = async (offersData, setLoading) => {
    try {
        await offerUploadFun(offersData);
        toast.success('Offer created successfully');
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};