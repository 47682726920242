import { useContext, useState } from 'react';
import handleChange from '../../../utils/handleChange';
import './orgLogin.css';
import { orgGetOTPFun, orgLoginFunAPI } from '../../../api/orgLoginAPI';
import { OrgAuthContext } from '../../../context/OrgAuthContext';
import { useNavigate } from 'react-router-dom';
import errorHandle from '../../../utils/errorHandle';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import phoneValid from '../../../utils/phoneValid';
import OTPInput from '../../otpinput/otpinput';
import TrvToLogo from '../../../assets/images/trvto-logo.png';
import { ReactComponent as TravelImg } from '../../../assets/images/trvto_travel.svg';
import { ReactComponent as Announcement } from '../../../assets/images/trvto_announcement.svg';
import { ReactComponent as Phone } from '../../../assets/icons/trvto_phone.svg';
import { ReactComponent as Email } from '../../../assets/icons/trvto_email.svg';

function OrgLogin({ setLoginSec }) {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({
        phoneNumber: '',
        otp: ''
    });
    const [phoneError, setPhoneError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('400');

    const { isOrgAuthenticated, orgLoginFun } = useContext(OrgAuthContext);

    async function getOrgOTP() {
        if (!phoneValid(userDetails.phoneNumber)) {
            setPhoneError('Please enter a valid phone number');
            return;
        }
        setPhoneError('');
        setLoading(true);
        try {
            const orgOTP = await orgGetOTPFun('trip', {
                phoneNumber: userDetails.phoneNumber
            });
            //End Loadin
            setLoading(false);
            //Set response to 200 telling OTP is sent
            setResponse('200');
            //Notifaction of OTP sent
            toast.success('OTP sent to your phone number');
        } catch (error) {
            errorHandle(error);
            //End loading animation
            setLoading(false);
        }
    }

    //check for valid phone number and
    //send login information to backend for verification
    async function handleOrgLogin() {
        // if (!phoneValid(userDetails.phoneNumber)) {
        //     setPhoneError('Please enter a valid phone number');
        //     return;
        // }
        if (userDetails.otp.length <= 5) {
            setOtpError('Please enter the complete OTP');
            console.log('Please enter the complete OTP');
            return;
        }
        setOtpError('');
        //Start loading animation
        setLoading(true);

        try {
            const loginData = await orgLoginFunAPI('trip', userDetails);
            //Show notifation of Login success
            toast.success('Login Successful');
            //End loading animation
            setLoading(false);
            orgLoginFun(loginData?.data);
            navigate('/org/dashboard');
        } catch (error) {
            errorHandle(error);
            //End loading animation
            setLoading(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            response !== '200' ? getOrgOTP() : handleOrgLogin();
        }
    };
    // if (isOrgAuthenticated) {
    //     // return <Navigate from="org/login" to="/org/dashboard" />;
    //     return <Navigate to="/org/dashboard" />;
    // }

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="orgLogin">
                <div>
                    <div className="orgLoginLogoDiv">
                        <img src={TrvToLogo} alt="trvto_logo" />
                    </div>
                    <div className="orgLoginDiv">
                        <Announcement />
                        <h1 className="orgLoginH1">Organizer Login</h1>
                        <div className="orgLoginInputDiv">
                            <input
                                placeholder="Organizer phone number"
                                name="phoneNumber"
                                onChange={(e) =>
                                    handleChange(e, setUserDetails)
                                }
                                onKeyDown={handleKeyDown}
                                value={userDetails.phoneNumber}
                                type="number"
                                className="orgLoginInput"
                            />
                            {phoneError && (
                                <span className="orgLoginError">
                                    {phoneError}
                                </span>
                            )}
                            {response === '200' && (
                                <div className="loginInputDiv">
                                    <p>Check text messages for your OTP</p>
                                    <label>OTP</label>
                                    <OTPInput
                                        length={6}
                                        setUserData={setUserDetails}
                                        keyName={'otp'}
                                        handleKeyDown={handleKeyDown}
                                    />
                                </div>
                            )}
                            {response === '200' && otpError && (
                                <span className="orgLoginError">
                                    {otpError}
                                </span>
                            )}
                            {/* <div className="orgLoginLinkDiv">
                            <Link
                                to="/org/register"
                                className="orgLoginRegister"
                            >
                                Register
                            </Link>
                        </div> */}
                        </div>

                        <button
                            type="button"
                            onClick={
                                response !== '200' ? getOrgOTP : handleOrgLogin
                            }
                            className="orgLoginBtn"
                        >
                            {response !== '200'
                                ? 'Send One Time Password'
                                : 'Login'}
                        </button>
                    </div>
                </div>

                <div className="orgLoginInfoDiv">
                    <TravelImg />
                    <h2>Want to partner with us? Contact Our team.</h2>
                    <div className="contact-details org-login orgContInfDiv">
                        <div className="details-item">
                            <div className="icon-con">
                                <Phone />
                            </div>
                            <a href="tel:9740665900">9742229962</a>
                        </div>
                        <div className="details-item">
                            <div className="icon-con">
                                <Email />
                            </div>{' '}
                            <a href="mailto:mr.sky.kj@gmail.com">
                                sagarb.b0@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrgLogin;
