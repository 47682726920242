import { WhyUs } from "../../../dummyData/homePage";
import WhyUsImg from '../../../assets/home/trvto_whyUs.png';
import Bridge from "../../../assets/icons/trvto_bridge.png";
import './whyUs.css';
import { useNavigate } from "react-router-dom";

const WhyUsSection = () => {
    const navigate = useNavigate();
    return (
        <div className="customContainer">
            <div className="sectionWhyUs">
                <div>
                    <img className="bannerImg" src={WhyUsImg} alt='trvto_why us' />
                </div>
                <div>
                    <p className='pageCount'>04 Why Us</p>
                    <p className='homeTitle'>Great opportunity for Adventure & travels</p>
                    <p className='description'>Our mission is to offer our customers raw, authentic, and safe adventure experiences. We understand that true adventure lies in genuine exploration, and we strive to provide that while ensuring the highest standards of safety. At TrvTo, your safety and enjoyment are our top priorities. Our team of experts rigorously checks and verifies all experiences to provide you with peace of mind as you explore the unknown.</p>
                    <div className='innerGrid'>
                        <img className="bridgeBackground" src={Bridge} alt='trvto_bridge' />
                        {WhyUs.map((item) => {
                            return (
                                <div key={item.id} className='whyUsGrid' style={{ backgroundColor: item.background }}>
                                    <img src={item.icon} alt={item.title} />
                                    <p style={{ color: item.color, fontWeight: 600 }}>{item.title}</p>
                                </div>
                            );
                        })}
                    </div>
                    <button className="readMoreBtn" onClick={() => navigate('/about-us')}>Read More</button>
                </div>
            </div>
        </div>
    );
};

export default WhyUsSection;
