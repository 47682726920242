import "./roomCard.css";
import imag from "../../assets/images/trvto_tripDetails1.jpg";
import { useState } from "react";

export function RoomCard() {
  const [rooms, setRooms] = useState(0);
  return (
    <div className="roomsCard">
      <img src={imag} alt="trvto_room" className="roomsCardImg" />
      <div className="roomsCardInfoDiv">
        <div className="roomsCardNameDiv">
          <span className="roomsCardName">Name of room</span>
          <span className="roomsCardPriceDiv">
            <span className="roomsCardName">₹600</span>
            <span className="roomsCardNight">/ night</span>
          </span>
        </div>
        <span className="roomsCardPerson">person x 1</span>
        <span className="roomsCardPerson">
          A bed in a spacious mixed dormitory with private lockers, AC, sea
          view, and a shared en-suite washroom{" "}
        </span>
        <div className="roomsCardSelectDiv">
          <div>
            <i>Icon</i>
            <span>Hot water</span>
          </div>
          {rooms === 0 ? (
            <button onClick={() => setRooms(1)}>Select Room</button>
          ) : (
            <div>
              <button>-</button> <span>{rooms}</span>
              <button>+</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
