import { Navigate, Route, Routes } from 'react-router-dom';
import OrgPackList from '../pages/orgPackList/OrgPackList';
import OrgRoomsList from '../pages/orgRoomsList/OrgRoomsList';
import OrgPackUpload from '../pages/orgPackUpload/OrgPackUpload';
import OrgRoomUpload from '../pages/orgRoomUpload/OrgRoomUpload';
import Footer from '../webPageSections/footer/Footer';
import OrgRegister from '../pages/orgRegister/OrgRegister';
import OrgHeader from '../webPageSections/orgPageSection/orgHeader/OrgHeader';
import Dashboard from '../pages/dashboard/Dashboard';
// import { OrgAuthProvider } from "../context/OrgAuthContext";
import OrgLoginPage from '../pages/orgLoginPage/OrgLoginPage';
import { useContext, useEffect } from 'react';
import { OrgAuthContext, OrgAuthProvider } from '../context/OrgAuthContext';
import OrgAccount from '../pages/orgAccount/OrgAccount';
import OrgTripDetails from '../pages/orgTripDetails/OrgTripDetails';
import OrgTripDateDetails from '../pages/orgTripDateDeails/OrgTripDateDetails';
import { OrgDatesProvider } from '../context/OrgDatesContest';
import Privacy from '../pages/privacy/Privacy';
import OrgTerms from '../pages/orgTerms/OrgTerms';
import OrgLeftMenu from '../components/orgLeftMenu/orgLeftMenu';
import OrgBilling from '../pages/orgBilling/orgBilling';
import OrgBooking from '../pages/orgBooking/OrgBooking';
import OrgReviews from '../pages/orgReviews/OrgReviews';
import { toast } from 'react-toastify';
import ContactUs from '../pages/contactUs/ContactUs';

let orgi = 0;
let orgTimeStampExpire;
let orgExpiryTime;

export default function OrgLayout() {
    const { isOrgAuthenticated } = useContext(OrgAuthContext);

    // orgTimeStampExpire = window.localStorage.getItem('orgExpiry');
    // if (window.location.pathname !== '/org/login' && orgi === 0) {
    //     orgExpiryTime = orgTimeStampExpire - Date.now();
    //     console.log(orgExpiryTime, orgi);
    //     orgi = 1;
    //     if (orgExpiryTime < 0) {
    //         toast.error('Session has expired. Login again to continue...');
    //         setTimeout(() => {
    //             window.localStorage.clear();
    //             window.location = '/org/login';
    //         }, 3000);
    //     } else {
    //         setTimeout(() => {
    //             toast.error('Session has expired. Login again to continue...');
    //             setTimeout(() => {
    //                 window.localStorage.clear();
    //                 window.location = '/org/login';
    //             }, 3000);
    //         }, orgExpiryTime);
    //     }
    // }
    return (
        <>
            {isOrgAuthenticated ? (
                <>
                    <OrgHeader />
                    <div className="org-main-container">
                        <OrgLeftMenu />
                        <main className="org-content">
                            <Routes>
                                {/* <Route
                                    path="/login"
                                    element={<OrgLoginPage />}
                                />*/}
                                <Route
                                    path="/register"
                                    element={<OrgRegister />}
                                />
                                <Route
                                    path="/*"
                                    element={<Navigate to="login" />}
                                />
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/my-pack"
                                    element={<OrgPackList />}
                                />
                                <Route
                                    path="/billing"
                                    element={<OrgBilling />}
                                />
                                <Route
                                    path="/my-room"
                                    element={<OrgRoomsList />}
                                />
                                <Route
                                    path="/bookings"
                                    element={<OrgBooking />}
                                />
                                <Route
                                    path="/reviews"
                                    element={<OrgReviews />}
                                />
                                <Route
                                    path="/create/pack"
                                    element={<OrgPackUpload />}
                                />
                                <Route
                                    path="/create/room"
                                    element={<OrgRoomUpload />}
                                />
                                <Route
                                    path="/account"
                                    element={<OrgAccount />}
                                />
                                <Route
                                    path="/trip/details/:tripId"
                                    element={
                                        <OrgDatesProvider>
                                            <OrgTripDetails />
                                        </OrgDatesProvider>
                                    }
                                />
                                <Route
                                    path="/trip/date/:tripDateId"
                                    element={<OrgTripDateDetails />}
                                />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/terms" element={<OrgTerms />} />
                                <Route
                                    path="/contact-us"
                                    element={<ContactUs />}
                                />
                            </Routes>
                        </main>
                        <Footer />
                    </div>
                </>
            ) : (
                <Routes>
                    <Route path="/login" element={<OrgLoginPage />} />
                    <Route path="/*" element={<Navigate to="login" />} />
                    <Route path="/register" element={<OrgRegister />} />
                </Routes>
            )}
        </>
    );
}
