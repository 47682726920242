import HomeCarousel from '../../../components/homeCarousel/homeCarousel';
import MobileCarousel from '../../../components/mobileCarousel/mobileCarousel';
import { DestiationData } from '../../../dummyData/destinationData';
import './adventure.css';

const AdventureSection = () => {
    return (
        <>
            <div className="destinationBg" />
            <div className="destinationSection customContainer" id='destinationSection'>
                <div className="alignCenter mobCenter">
                    <div>
                        <p className='pageCount'>03 Journey Junction</p>
                        <p className="homeTitle">Your Gateway to Adventure</p>
                    </div>
                </div>
                <div className='mobHidden'>
                    <HomeCarousel data={DestiationData} />
                </div>
                <div className="mobDisplay">
                    <MobileCarousel type='destination' data={DestiationData} />
                </div>
            </div>
        </>
    );
}

export default AdventureSection;