import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userInfoFun } from "../../api/userAPI";
import DefaultUser from "../../assets/icons/trvto_avatar.svg";
import "./account.css";

export default function Account() {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const res = await userInfoFun();
      setData(res.data);
    };
    fetchUserData();
  }, []);

  return (
    <div className="account-container">
      <div className="account-header">
        <img
          className="account-user-pic"
          src={data?.profilePicture || DefaultUser}
          alt="trvto_User"
        />
        <h2>Welcome, {data?.name || "User"}</h2>
        <p>Manage your account and explore your preferences</p>
      </div>

      <div className="account-info">
        <div className="info-item">
          <strong>Contact Number:</strong> <span>{data?.phoneNumber || "N/A"}</span>
        </div>
        <div className="info-item">
          <strong>Email:</strong> <span>{data?.emailId || "N/A"}</span>
        </div>
      </div>

      <div className="account-actions">
        <Link to="/my-booking" className="account-link">Your Bookings</Link>
        <Link to="/my-wishlist" className="account-link">Your Wishlist</Link>
      </div>
    </div>
  );
}
