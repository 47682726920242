import handleTextChange from '../../utils/handleTextChange';
import { useState } from 'react';
import './supportAssistance.css';
import Pagination from '../../components/pagination/Pagination';
import SupportData from '../../dummyData/support.json';
import Dialog from '../../components/dialogModal/dialogModal';
import ReactLoading from 'react-loading';
import {
    getSupportInfo,
    submitTicket
} from './supportAssistanceFun/supportAssistancefun';

export default function SupportAssistance() {
    const [modal, setModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState({});
    const [ticketComment, setTicketComment] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const totalData = tableData.length - 1;
    const tableHeader = [
        'Name',
        'Phone Number',
        'Email ID',
        // 'Customer ID',
        'Place Name',
        // 'Location',
        'Date & Time',
        // 'Time of Booked',
        'Type',
        'Number of People',
        'Price',
        'Organizer Name',
        'Pickup Location',
        'Action'
    ];

    const paginationClick = (number, offSetNumber) => {
        if (Math.ceil(totalData / 10) > 1) {
            setPageNumber(+number);
            setOffSet(+offSetNumber);
        }
    };

    const handleSearch = () => {
        getSupportInfo(selectedType, searchText, setTableData, setLoading);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getSupportInfo(selectedType, searchText, setTableData, setLoading);
        }
    };

    const handleTicketSave = () => {
        const data = {
            userId: rowData.userId._id,
            packId: rowData.packId._id,
            tripDateId: rowData.bookedTrip._id,
            bookingId: rowData._id,
            type: rowData.packId.quickView.type,
            status: 'In_progress',
            description: ticketComment,
            comments: [
                // {
                //     comment: ticketComment,
                //     name: localStorage.getItem('supName'),
                //     by: localStorage.getItem('supId'),
                //     at: new Date()
                // }
            ],
            assignedTo: localStorage.getItem('supId')
        };
        submitTicket(selectedType, data, setLoading);
    };

    console.log(tableData);

    return (
        <div className="supportAssContainer">
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="supportSearchContainer">
                <div className="supportAssSearchDiv">
                    <select
                        name="date"
                        id="date"
                        className="supportSelect select mr-2"
                        onChange={(e) => setSelectedType(e.target.value)}
                    >
                        <option value="">Choose search type</option>
                        <option value="trip">Trip</option>
                        <option value="guide">Guide</option>
                        <option value="camp">Camp</option>
                    </select>
                    <div className="supAssdivider" />
                    <input
                        placeholder="Search here..."
                        onChange={(e) => handleTextChange(e, setSearchText)}
                        value={searchText}
                        onKeyDown={handleKeyDown}
                        className="supportAssSearchInput"
                    />
                    <button
                        onClick={handleSearch}
                        className="supportAssSearchBtn"
                    >
                        Search
                    </button>
                </div>
                {/* <div className="supportAssResultDiv">
                Search key word Search result
                <div>Customer Details</div>
                <div>
                    Bookings
                    <div>
                        Each booking will contain - Booking Id, name, no. of
                        peolpe, price, booked on, trip on, email, phone number,
                        Resend details button, comment, payment details, raise
                        ticket,
                    </div> */}
                {/* To send email and msg once more for particular package */}
                {/* <button>Send Email/Msg</button>
                </div>
            </div> */}
            </div>
            {tableData.length > 0 && (
                <>
                    <h4 className="supportAssLabel">Search Result</h4>
                    <div className="tableContainer">
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData?.map((row, rowIndex) => {
                                    return (
                                        <tr
                                            key={rowIndex}
                                            className="tableBodyRow"
                                        >
                                            <td>{row.userId.name}</td>
                                            <td>{row.userId.phoneNumber}</td>
                                            <td>{row.userId.emailId}</td>
                                            {/* <td>{row.customerId}</td> */}
                                            <td>{row.packId.title}</td>
                                            {/* <td>{row.location}</td> */}
                                            <td>
                                                {
                                                    row.bookedTrip.startDate.split(
                                                        'T'
                                                    )[0]
                                                }
                                            </td>
                                            {/* <td>{row.bookingTime}</td> */}
                                            <td>{row.packId.quickView.type}</td>
                                            <td>{row.userDetails.length}</td>
                                            <td>{row.amountPerSeat}</td>
                                            <td>{row.packId.organizerName}</td>
                                            <td>{row.pickupLocation}</td>
                                            <td className="accBtnContainer">
                                                <button className="actBtn payoutBtn">
                                                    Send Email
                                                </button>
                                                <button
                                                    className="actBtn billBtn"
                                                    onClick={() => {
                                                        setModal(true);
                                                        setRowData(row);
                                                    }}
                                                >
                                                    Create Ticket
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="responsiveCardContainer">
                            {tableData?.map((row, rowIndex) => (
                                <div key={rowIndex} className="responsiveCard">
                                    <div className="cardContent">
                                        <p>
                                            <strong>{tableHeader[0]}</strong>{' '}
                                            {row.userId.name}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[1]}</strong>{' '}
                                            {row.userId.phoneNumber}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[2]}</strong>{' '}
                                            {row.userId.emailId}
                                        </p>
                                        {/* <p>
                                            <strong>{tableHeader[3]}</strong>{' '}
                                            {row.customerId}
                                        </p> */}
                                        <p>
                                            <strong>{tableHeader[4]}</strong>{' '}
                                            {row.packId.title}
                                        </p>
                                        {/* <p>
                                            <strong>{tableHeader[5]}</strong>{' '}
                                            {row.location}
                                        </p> */}
                                        <p>
                                            <strong>{tableHeader[6]}</strong>{' '}
                                            {
                                                row.bookedTrip.startDate.split(
                                                    'T'
                                                )[0]
                                            }
                                        </p>
                                        {/* <p>
                                            <strong>{tableHeader[7]}</strong>{' '}
                                            {row.bookingTime}
                                        </p> */}
                                        <p>
                                            <strong>{tableHeader[8]}</strong>{' '}
                                            {row.packId.quickView.type}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[9]}</strong>{' '}
                                            {row.userDetails.length}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[10]}</strong>{' '}
                                            {row.amountPerSeat}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[11]}</strong>{' '}
                                            {row.packId.organizerName}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[12]}</strong>{' '}
                                            {row.pickupLocation}
                                        </p>
                                        <div className="accBtnContainer">
                                            <button className="actBtn payoutBtn">
                                                Send Email
                                            </button>
                                            <button
                                                className="actBtn billBtn"
                                                onClick={() => setModal(true)}
                                            >
                                                Create Ticket
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {tableData.length > 10 && (
                        <Pagination
                            paginationClick={paginationClick}
                            numberOfRows={totalData}
                            offSet={offSet}
                            pageNumber={pageNumber}
                        />
                    )}
                </>
            )}
            {modal && (
                <Dialog openModal={modal} heading={'Create Ticket'}>
                    <div className="dialogContent">
                        <p className="ticketTitle">Name of Booking</p>
                        <p className="ticketContent">{rowData.userId.name}</p>
                        <p className="ticketTitle">Booking ID</p>
                        <p className="ticketContent">
                            {rowData.bookedTrip._id}
                        </p>
                        <p className="ticketTitle">Date</p>
                        <p className="ticketContent">
                            {rowData.bookedTrip.startDate.split('T')[0]}
                        </p>
                        <p className="ticketTitle">Comment</p>
                        <textarea
                            className="ticketComment"
                            value={ticketComment}
                            onChange={(e) => setTicketComment(e.target.value)}
                        />
                        <div className="alignCenter">
                            <button
                                className="dialogActBtn cancel"
                                onClick={() => setModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="dialogActBtn submit"
                                onClick={handleTicketSave}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    );
}
