import ReviewOne from '../assets/home/trvto_ReviewOne.png';

export const ReviewsData = [
    {
        id: 1,
        image: ReviewOne,
        rating: 4,
        description: 'I work with Alguneb Johnl on many projects, he always on tolda forn tha excel my expectations with his qualitywork andani fastestopasn told up services, very smooth and simpl communication one the way to go upe.',
        author: 'Jaane Cooper',
        designation: 'Founder, Enosis Soft'
    }
];