import { useEffect, useRef, useState } from 'react';
import Navigation from '../../../assets/icons/trvto_navigation.png';
import ReactLoading from 'react-loading';
import './holiday.css';
import { useLocation, useNavigate } from 'react-router-dom';
import TypingAnimation from '../../../components/typingAnimation/TypingAni';

const Holiday = ({ className, style }) => {
    const [searchField, setSearchField] = useState('');
    const [loading, setLoading] = useState(false);
    const [offSet, setOffSet] = useState(0);
    const [limit, setLimit] = useState(9);
    const inputFo = useRef();
    const [spanDis, setSpanDis] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const tripText = [
        'Search Your Destination',
        'Explore Popular Destinations',
        'Find Your Next Adventure'
    ];

    useEffect(() => {
        const currentPath = location.pathname;
        const isSearchPage = currentPath.startsWith('/search-result/trip');

        if (!isSearchPage) {
            setSearchField('');
        }
    }, [location]);

    const headerTypingAniStyle = {
        searchContainer: {
            top: 0,
            left: 0
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        if (searchField.trim() !== '') {
            setLimit(9);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                navigate(`/search-result/trip/${searchField}`, {
                    state: { limit }
                });
            }, 300);
        }
    };

    function handleSpan() {
        setSpanDis(false);
        inputFo.current.focus();
    }

    return (
        <div
            style={style}
            className={`holidaySearchWrapper alignCenter ${className}`}
        >
            <div className="alignCenter">
                <img src={Navigation} alt="trvto_navigation" />
                <div className="relative">
                    <input
                        ref={inputFo}
                        value={searchField}
                        onBlur={() => setSpanDis(true)}
                        onClick={handleSpan}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSearchField(e.target.value)}
                        className="holidaySearch"
                    />
                    {spanDis && !searchField && (
                        <TypingAnimation
                            texts={tripText}
                            handleSpan={handleSpan}
                            seConStyle={headerTypingAniStyle.searchContainer}
                        />
                    )}
                </div>
            </div>
            <button
                type="button"
                className="hoilidayrSearchBtn"
                onClick={handleSearch}
            >
                Search
            </button>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
        </div>
    );
};

export default Holiday;
