import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import './orgTripDetails.css';
import DetailsTabs from '../../components/detailsTabs/DetailsTabs';
import DetailsImageDisplay from '../../components/detailsImageDisplay/DetailsImageDisplay';
import fetchOrgTripData from './orgTripDetailsFun/fetchOrgTripData';
import imageDataObject from '../../dummyData/tripDetailsImageData.json';
import OrgTripDateTab from '../../components/orgTripDateTab/OrgTripDateTab';
import { OrgDatesContext } from '../../context/OrgDatesContest';
import EmptyPage from '../../components/empty-page/emptyPage';
import ReviewStars from '../../components/reviewStars/ReviewStars';

export default function OrgTripDetails() {
    const [packData, setPackData] = useState({});
    const [loading, setLoading] = useState(false);

    const { dates, setDates } = useContext(OrgDatesContext);

    useEffect(() => {
        fetchOrgTripData(setLoading, setPackData, setDates);
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            {Object.keys(packData).length !== 0 ? (
                <>
                    <div className="orgTripDetailsHeadingDiv">
                        <h1 className="detailsImageDisplayName">
                            {packData.title}
                        </h1>
                        <div>
                            {/* {whichType === "camp" ? (
          <span className="tripDetailsSubDetails">City Name</span>
        ) : ( */}
                            <div className="tripBriefInfo">
                                <span className="tripDetailsSubDetails">
                                    {packData?.days +
                                        'D/' +
                                        packData?.nights +
                                        'N'}
                                </span>
                                {/* )} */}

                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    {dates.length !== 0 &&
                                        dates[0]?.startDate?.split('T')[0]}{' '}
                                    Onwards
                                </span>
                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    {packData?.quickView?.startTime}
                                </span>
                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    {packData?.quickView?.stay}
                                </span>
                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    {packData?.quickView?.food}
                                </span>
                                <span className="tripDetailsSubDetails">
                                    {packData?.quickView?.type}
                                </span>
                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    {packData?.quickView?.difficulty}
                                </span>
                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    {packData?.organizerName}
                                </span>
                                <span className="tripDetailsDot"></span>
                                <span className="tripDetailsSubDetails">
                                    <ReviewStars
                                        rating={packData?.rating}
                                        className="reviews"
                                    />
                                    <span>{packData?.rating}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="tripDetails">
                        <DetailsImageDisplay imageData={packData.images} />
                        <div className="tripDetailsTextBooking">
                            <div className="tripDetailsText">
                                <DetailsTabs tripDetailsData={packData} />
                            </div>
                            <div className="tripDetailsBooking">
                                <OrgTripDateTab
                                    price={packData.price[0].price}
                                    setDates={setDates}
                                    days={packData?.days}
                                />
                            </div>
                        </div>
                    </div>{' '}
                </>
            ) : (
                <EmptyPage />
            )}
        </div>
    );
}
