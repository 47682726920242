import DirectInput from '../../../../components/directInput/DirectInput';
import DropdownReg from '../../../../components/dropdownReg/DropdownReg';
import Textarea from '../../../../components/textarea/Textarea';

export default function CompanyAddress(props) {
    const { formData, setFormData } = props;
    const state = ['Karnataka'];
    // const city = ["Bengaluru", "Mysuru", "Huballi", "Mangaluru"];
    const country = ['India'];
    return (
        <div>
            <h2 className="orgRegisterH2">Address</h2>
            <div className="orgRegisterSecDiv">
                <DirectInput
                    label="Name"
                    name={'name'}
                    value={formData.address.name}
                    type="text"
                    placeholder="Company name"
                    setFormData={setFormData}
                    min={3}
                    limit={30}
                    nest="address"
                />
                <DirectInput
                    label="Address line 1"
                    name={'addressLine1'}
                    value={formData.address.addressLine1}
                    placeholder="address"
                    setFormData={setFormData}
                    min={3}
                    limit={40}
                    nest="address"
                />
                <DirectInput
                    label="Address line 2"
                    name={'addressLine2'}
                    value={formData.address.addressLine2}
                    placeholder="address"
                    setFormData={setFormData}
                    min={3}
                    limit={40}
                    nest="address"
                />
                <DirectInput
                    label="Pincode"
                    name={'pinCode'}
                    value={formData.address.pinCode}
                    type="text"
                    placeholder="Pincode"
                    setFormData={setFormData}
                    min={3}
                    limit={8}
                    nest="address"
                />
                <DirectInput
                    label="City"
                    name={'city'}
                    value={formData.address.city}
                    type="text"
                    placeholder="City"
                    setFormData={setFormData}
                    min={2}
                    limit={30}
                    nest="address"
                />
                <DropdownReg
                    label="State"
                    name={'state'}
                    options={state}
                    value={formData.address.state}
                    req={true}
                    setFormData={setFormData}
                    nest="address"
                />
                <DropdownReg
                    label="Country"
                    name={'country'}
                    options={country}
                    value={formData.address.country}
                    req={true}
                    setFormData={setFormData}
                    nest="address"
                />
            </div>
        </div>
    );
}
