import { ReactComponent as Mail } from "../../../assets/icons/trvto_mail.svg";
import { ReactComponent as Profile } from "../../../assets/icons/trvto_profile.svg";
import { ReactComponent as Phone } from "../../../assets/icons/trvto_number.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/trvto_time.svg";
import { ReactComponent as Path } from "../../../assets/icons/trvto_path.svg";
import { ReactComponent as Price } from "../../../assets/icons/trvto_price.svg";
import { ReactComponent as Location } from "../../../assets/icons/trvto_place.svg";

const MoreItem = (props) => {
    const getIcon = (type) => {
        switch (type) {
            case "email":
                return Mail;
            case "profile":
                return Profile;
            case "phone":
                return Phone;
            case "location":
                return Location;
            case "calendar":
                return Calendar;
            case "price":
                return Price;
            case "place":
                return Path;
            default:
                return null;
        }
    };

    const IconComponent = getIcon(props.type);

    return (
        <div className="moreDetailsItem">
            <div className="iconContainer">{IconComponent && <IconComponent />}</div>
            <p>{props.data}</p>
        </div>
    );
};

export default MoreItem;