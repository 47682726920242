import perImage from "../../../assets/icons/trvto_tourGuideSelected.png";
import ReviewStars from "../../reviewStars/ReviewStars";
import "./reviews.css";

export default function Reviews({ data }) {
  return (
    <div>
      {data?.map((review, reviewIndex) => {
        return (
          <div key={reviewIndex} className="reviewDiv">
            <div className="reviewImgText">
              <img
                src={perImage}
                alt={review.userName + "image"}
                className="reviewImg"
                al="trvto_name"
              />
              <div className="reviewStarsName">
                {/* <span className="reviewStars">stars</span> */}
                <ReviewStars rating={review.rating} className="reviewStars" />
                <span className="reviewUserName">{review.userName}</span>
              </div>
            </div>
            <div className="reviewCommentDiv">
              {/* <span className="reviewLocation">location and time</span> */}
              <span className="reviewComment">{review.comment}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
