import https from "../init/https";

//BLOG-01 : Upload complete content of blog
export async function blogUploadFun(data) {
    const result = await https.post(`/data/blog`, data);
    return result;
}

//BLOG-02 : Upload content of dropdown
export async function dropdownUploadFun(data) {
    const result = await https.post(`/data/dropdown`, data);
    return result;
}

//BLOG-03 : Edit blog
export async function blogEditFun(blogId, data) {
    const result = await https.patch(`/data/blog/${blogId}`, data);
    return result;
}

//BLOG-03 : Edit dropdown
export async function dropdownEditFun(dropdownId, data) {
    const result = await https.patch(`/data/dropdown/${dropdownId}`, data);
    return result;
}

//BLOG-05 : Delete blog based on the blogId
export async function blogDeleteFun(blogId) {
    const result = await https.delete(`/data/blog/${blogId}`);
    return result;
}

//BLOG-06 : Delete blog based on the dropdownId
export async function dropdownDeleteFun(dropdownId) {
    const result = await https.delete(`/data/blog/${dropdownId}`);
    return result;
}

//BLOG-07 : Upload content of offer
export async function offerUploadFun(data) {
    const result = await https.post(`/data/offer`, data);
    return result;
}

//BLOG-08 : Upload content of offer
export async function offerEditFun(offerId, data) {
    const result = await https.patch(`/data/offer/edit/${offerId}`, data);
    return result;
}

//BLOG-09 : Upload content of faq
export async function faqUploadFun(data) {
    const result = await https.post(`/data/faq`, data);
    return result;
}

//BLOG-10 : Upload content of faq
export async function faqEditFun(faqId, data) {
    const result = await https.patch(`/data/faq/${faqId}`, data);
    return result;
}

//BLOG-11 : Delete faq based on the faqId
export async function faqDeleteFun(faqId) {
    const result = await https.delete(`/data/faq/${faqId}`);
    return result;
}