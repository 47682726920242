import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";
import { storage } from "../../../config/firebase";

export const uploadImageToFirebase = async (file, uuid) => {
  try {
    const filePath = `Blogs/${uuid}/${file.name}`;
    const storageRef = ref(storage, filePath);

    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    console.log("File available at", downloadURL);

    return downloadURL; // Ensure the URL is returned
  } catch (error) {
    console.error("Upload failed:", error);
    toast.error("Failed to upload image. Please try again.");

    // Optionally, you can throw the error to handle it where this function is called
    throw error;
  }
};
