import Carousel from "../carousel/Carousel";
import "./detailsImageDisplay.css";

function DetailsImageDisplay({ imageData }) {
  return (
    <>
      <Carousel customClass='mobSlider' imageData={imageData} />
      <div className="tripDetailsDiv">
        <div className="tripDetailsImages">
          <div className="tripDetailsMainImage">
            <img alt="trvto_nature" src={imageData[0]} className="tripDetailsMainImg" />
          </div>
          <div className="tripDetailsOtherImagesFirst">
            <img alt="trvto_nature" src={imageData[1]} className="tripDetailsOtherImg1" />
            <img alt="trvto_nature" src={imageData[2]} className="tripDetailsOtherImg2" />
          </div>
          <div className="tripDetailsOtherImages">
            <img alt="trvto_nature" src={imageData[3]} className="tripDetailsOtherImg3" />
            <img alt="trvto_nature" src={imageData[4]} className="tripDetailsOtherImg4" />
          </div>
        </div>
        <button className="detailsImageDisplayMoreButton">Explore More</button>
      </div>
    </>
  );
}

export default DetailsImageDisplay;
