import { ReactComponent as RatingStar } from '../../assets/icons/trvto_rating-star.svg';
import { ReactComponent as RatingStarHalf } from '../../assets/icons/trvto_rating-star-half.svg';
import { ReactComponent as RatingStarEmpty } from '../../assets/icons/trvto_rating-star-empty.svg';
import { useState } from 'react';

export default function ReviewStars({ rating, className, onRatingChange, isInteractive = false }) {
    const [currentRating, setCurrentRating] = useState(rating);
    const fullStars = Math.floor(currentRating);
    const hasHalfStar = currentRating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const handleStarClick = (newRating) => {
        if (!isInteractive) return;
        setCurrentRating(newRating);
        if (onRatingChange) onRatingChange(newRating);
    };

    return (
        <div className={className}>
            {[...Array(5)].map((_, index) => {
                const starRating = index + 1;
                return (
                    <span key={index} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleStarClick(starRating);
                    }} style={{ cursor: isInteractive ? 'pointer' : 'default' }}>
                        {starRating <= fullStars ? (
                            <RatingStar style={{ marginRight: '4px' }} />
                        ) : starRating - 1 < currentRating && hasHalfStar ? (
                            <RatingStarHalf style={{ marginRight: '4px' }} />
                        ) : (
                            <RatingStarEmpty style={{ marginRight: '4px' }} />
                        )}
                    </span>
                );
            })}
        </div>
    );
}
