import "./blogCard.css";

export default function BlogCard({ blogData }) {
  const openBlog = () => {
    window.open(`/blog/details/${blogData._id}`, '_blank');
  };
  return (
    <div className="blogCard" onClick={openBlog}>
      <img alt="trvto_blog" src={blogData?.image} className="blogImage" />
      <div className="blogCardTextDiv">
        <h3 className="blogCardName">{blogData?.name}</h3>
        <p className="blogCardDis">{blogData?.shortDescription}</p>
      </div>
    </div>
  );
}
