import { useEffect, useState } from 'react';
import './orgPackUpload.css';
import Itinerary from './orgPackUploadSection/itinerary/Itinerary';
import Tags from '../../components/tags/Tags';
import FAQ from './orgPackUploadSection/FAQ/FAQ';
import Price from './orgPackUploadSection/price/Price';
import QuickView from './orgPackUploadSection/quickView/QuickView';
import Basic from './orgPackUploadSection/basic/Basic';
import ImageUpOrg from './orgPackUploadSection/images/ImageUpOrg';
import InEx from './orgPackUploadSection/inEx/InEx';
import orgPackUpSubmit from './orgPackUpFun/orgPackUpSubmit';
import ReactLoading from 'react-loading';
import Terms from './orgPackUploadSection/terms/Terms';
import errorHandle from '../../utils/errorHandle';
import { getDropdownFun } from '../../api/infoAPI';

export default function OrgPackUpload() {
    const [packData, setPackData] = useState({
        title: '',
        overview: '',
        days: 0,
        nights: 0,
        quickView: {
            type: '',
            difficulty: '',
            startTime: '',
            endTime: '',
            duration: '',
            stay: '',
            where: '',
            food: '',
            trekDistance: '',
            baseCities: ''
        },
        itinerary: [
            {
                dayHeading: '',
                dayDescription: ''
            }
        ],
        FAQ: [{ question: '', answer: '' }],
        price: [{ price: 0, startCity: '', pickupLocation: [] }],
        withoutTransportPrice: 0,
        tags: []
    });
    const [tempData, setTempData] = useState({
        included: '',
        excluded: '',
        terms: ''
    });
    const [imgData, setImgData] = useState({
        wallImg: [],
        thumbImg: []
    });
    const [loading, setLoading] = useState(false);
    const [cityDrop, setCityDrop] = useState([
        ({ name: 'Bengaluru', displayName: 'Bengaluru/Bangalore' },
        { name: 'Mysuru', displayName: 'Mysuru/Mysore' })
    ]);

    useEffect(() => {
        async function getDropCity() {
            try {
                setLoading(true);
                const city = await getDropdownFun('trip', 'Cities');
                if (city.data) {
                    setCityDrop(city.data.dropdown);
                }
                setLoading(false);
            } catch (error) {
                errorHandle(error);
            }
        }
        getDropCity();
    }, []);

    return (
        <div className="orgPackUpDiv">
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <Basic packData={packData} setPackData={setPackData} />
            {/* //Small bold Information which contains slots, type, difficulty, start time, end time,
        // duration, stay, where, food, trek distance */}
            <QuickView packData={packData} setPackData={setPackData} />
            {/* Day wise internary  */}
            <Itinerary packData={packData} setPackData={setPackData} />
            <ImageUpOrg imgData={imgData} setImgData={setImgData} />
            <InEx tempData={tempData} setTempData={setTempData} />
            <FAQ packData={packData} setPackData={setPackData} />
            <Terms tempData={tempData} setTempData={setTempData} />
            <Price
                packData={packData}
                setPackData={setPackData}
                cityDrop={cityDrop}
            />
            <Tags data={packData} setData={setPackData} />

            <button
                type="button"
                className="orgPackUpSubmitBtn blueBtn"
                onClick={() =>
                    orgPackUpSubmit(packData, tempData, imgData, setLoading)
                }
            >
                Submit
            </button>
        </div>
    );
}
