import DirectInput from '../../../../components/directInput/DirectInput';
import DropdownReg from '../../../../components/dropdownReg/DropdownReg';
import './quickView.css';

export default function QuickView({ packData, setPackData }) {
    const typeDrop = ['Trek', 'Sight Seeing', 'Adventur'];
    const diffDrop = ['Easy', 'Medium', 'Difficult'];
    const foodDrop = ['Veg', 'Non-veg', 'Veg & Non-veg'];
    return (
        <div>
            <h2 className="orgPackUpH2">Quick View</h2>
            <div className="orgPackUpSecDiv">
                <DropdownReg
                    label="Type"
                    name={'type'}
                    options={typeDrop}
                    value={packData.quickView.type}
                    req={true}
                    setFormData={setPackData}
                    nest="quickView"
                />
                <DropdownReg
                    label="Difficulty"
                    name={'difficulty'}
                    options={diffDrop}
                    value={packData.quickView.difficulty}
                    req={true}
                    setFormData={setPackData}
                    nest="quickView"
                />
                <DirectInput
                    label="Start Time"
                    name="startTime"
                    value={packData.quickView.startTime}
                    type="text"
                    placeholder="Ex - 9:00 PM"
                    setFormData={setPackData}
                    min={6}
                    limit={9}
                    nest="quickView"
                />
                <DirectInput
                    label="End Time"
                    name="endTime"
                    value={packData.quickView.endTime}
                    type="text"
                    placeholder="Ex - 9:00 PM"
                    setFormData={setPackData}
                    min={6}
                    limit={9}
                    nest="quickView"
                />
                <DirectInput
                    label="Duration"
                    name="duration"
                    value={packData.quickView.duration}
                    type="text"
                    placeholder="2D/1N"
                    setFormData={setPackData}
                    min={2}
                    limit={9}
                    nest="quickView"
                />
                <DirectInput
                    label="Stay"
                    name="stay"
                    value={packData.quickView.stay}
                    type="text"
                    placeholder="Tent, Sharing rooms"
                    setFormData={setPackData}
                    min={6}
                    limit={30}
                    nest="quickView"
                />
                <DirectInput
                    label="Where"
                    name="where"
                    value={packData.quickView.where}
                    type="text"
                    placeholder="Destinotion palce name"
                    setFormData={setPackData}
                    min={6}
                    limit={30}
                    nest="quickView"
                />
                <DropdownReg
                    label="Food"
                    name={'food'}
                    options={foodDrop}
                    value={packData.quickView.food}
                    req={true}
                    setFormData={setPackData}
                    nest="quickView"
                />
                <DirectInput
                    label="Trek Distance"
                    name="trekDistance"
                    value={packData.quickView.trekDistance}
                    type="text"
                    placeholder="Trek distance in Km (Ex - 2 Km), if Not a trek just enter 0 Km"
                    setFormData={setPackData}
                    min={3}
                    limit={8}
                    nest="quickView"
                />
                <DirectInput
                    label="Base Cities"
                    name="baseCities"
                    value={packData.quickView.baseCities}
                    type="text"
                    placeholder="Starting cities (ex - Bengaluru, Mysuru)"
                    setFormData={setPackData}
                    min={3}
                    limit={40}
                    nest="quickView"
                />
            </div>
        </div>
    );
}
