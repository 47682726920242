import { ReactComponent as Arrow } from '../../assets/home/trvto_arrow.svg';

const DestinationCard = ({ data }) => {
    const handleClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            {data.map((item, index) => (
                <div key={index} className="carousel-item">
                    <img src={item.image} alt={item.title} />
                    <div className="carouselInnerCard">
                        <div className='innerBorder' />
                        <div className="alignCenter">
                            <p>{item.title}</p>
                            <button className='carouselCardInnerBtn' onClick={() => handleClick(item.url)}><Arrow /></button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default DestinationCard;
