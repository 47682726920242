import './leftMenu.css';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { LeftMenuData } from './leftMenuData';
import { Link } from 'react-router-dom';
import Logout from '../../assets/icons/trvto_logout.svg';
import CollapseMenuIcon from '../../assets/icons/trvto_sidebarMenu.svg';
import ArrowBack from '../../assets/icons/trvto_closeWhite.svg';
import { SupAuthContext } from '../../context/SupAuthContext';

export default function SupLeftMenu({ showSidebar }) {
    const { SupLogoutFun } = useContext(SupAuthContext);
    const [sidebar, setSidebar] = useState(0);
    const [expandSidebar, setExpandSidebar] = useState(false);
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target)
            ) {
                setExpandSidebar(false);
            }
        };

        // Attach event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button
                type="button"
                onClick={() => setExpandSidebar(!expandSidebar)}
                className={`menuExpand ${
                    expandSidebar ? 'menuExpandActive' : ''
                }`}
            >
                <img
                    src={!expandSidebar ? CollapseMenuIcon : ArrowBack}
                    alt="trvto_Close"
                    width={24}
                    height={24}
                />
            </button>
            <nav
                className={`nav-menu ${
                    expandSidebar || showSidebar ? 'active' : ''
                }`}
                ref={sidebarRef}
            >
                <ul className="nav-menu-items">
                    <div>
                        <li className={'nav-text show-menu'}>
                            <Link
                                to="#"
                                onClick={() => setExpandSidebar(!expandSidebar)}
                            >
                                <img
                                    src={
                                        !expandSidebar
                                            ? CollapseMenuIcon
                                            : ArrowBack
                                    }
                                    alt="trvto_Close"
                                    width={30}
                                    height={30}
                                />
                            </Link>
                        </li>
                        {LeftMenuData.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        index === sidebar
                                            ? 'nav-text active'
                                            : 'nav-text'
                                    }
                                >
                                    <Link
                                        to={item.path}
                                        onClick={() => setSidebar(index)}
                                    >
                                        <img
                                            src={
                                                index === sidebar
                                                    ? item.activeIcon
                                                    : item.icon
                                            }
                                            alt={item.title}
                                        />
                                        <span
                                            className={
                                                !expandSidebar && !showSidebar
                                                    ? 'nav-title'
                                                    : 'show'
                                            }
                                        >
                                            {item.title}
                                        </span>
                                    </Link>
                                </li>
                            );
                        })}
                    </div>
                    <div>
                        <li
                            className={
                                sidebar === 13 ? 'nav-text active' : 'nav-text'
                            }
                            onClick={() => setSidebar(13)}
                        >
                            <Link onClick={() => SupLogoutFun()}>
                                <img src={Logout} alt="trvto_logout" />
                                <span
                                    className={
                                        !expandSidebar && !showSidebar
                                            ? 'nav-title'
                                            : 'show'
                                    }
                                >
                                    Logout
                                </span>
                            </Link>
                        </li>
                    </div>
                </ul>
            </nav>
        </>
    );
}
