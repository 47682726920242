import { useContext, useEffect, useRef, useState } from 'react';
import './tripBookingTab.css';
import handleChange from '../../../utils/handleChange';
import Dropdown from '../../../components/dropdown/Dropdown';
import { BookingDataContext } from '../../../context/BookingDataContext';
import { Navigate } from 'react-router-dom';
import offerApplyTrip from './tripBookingTabFun/offerApplyTrip';
import handleBaseCity from './tripBookingTabFun/handleBaseCity.js';
import tripBookingClick from './tripBookingTabFun/tripBookingClick.js';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { ReactComponent as Chevron } from '../../../assets/icons/trvto_downArrow.svg';
import 'react-dates/lib/css/_datepicker.css';

// From for selecting number of people, date and booking the a particualr trip/tretment/guid
export default function TripBookingTab({
    // tripBookingClick,
    packData,
    allowedDates,
    packDates,
    setLoading
}) {
    const bookingTabRef = useRef(null);
    const transport = ['With transport', 'Without transport'];
    const numPeople = [1, 2, 3, 4, 5, 6];
    //use local page state and later update the context
    //so that everthing under the context doesnt get re-rendered for small change
    const [booking, setBooking] = useState({
        packDateId: '',
        title: '',
        people: 1,
        date: null,
        offerCode: '',
        startCity: '',
        pickupLocation: '',
        transport: 'With transport'
    });
    const [offerError, setOfferError] = useState('');
    const [reviewPage, setReviewPage] = useState(false);
    const [index, setIndex] = useState(0);
    const [offerCode, setOfferCode] = useState('');
    const [offerData, setOfferData] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const [amount, setAmount] = useState({
        actualPrice: 0,
        peopleAmo: 0,
        offerAmo: 0,
        totalAmo: 0
    });
    const startCity = useRef(
        packData.price.map(function (item) {
            return item.startCity;
        })
    );
    //states for date picker
    const [focused, setFocused] = useState(false);
    // const [selectedDate, setSelectedDate] = useState(null);
    // const allowedDates = [
    //   moment("2024-06-20"),
    //   moment("2024-06-22"),
    //   moment("2024-06-25"),
    //   // Add more dates as needed
    // ];
    const isDayBlocked = (day) => {
        if (!Array.isArray(allowedDates)) return false;
        return !allowedDates.some((allowedDate) =>
            allowedDate.isSame(day, 'day')
        );
    };
    const { bookingData, setBookingData } = useContext(BookingDataContext);

    function handlePromoCode(e) {
        const capOffer = e.target.value.toUpperCase();
        setOfferCode(capOffer);
    }

    useEffect(() => {
        if (isExpanded && bookingTabRef.current) {
            window.scrollTo({ top: bookingTabRef.current.offsetTop - 100, behavior: 'smooth' });
        }
    }, [isExpanded]);

    useEffect(() => {
        const peopleAmo =
            booking.transport === 'Without transport'
                ? packData.withoutTransportPrice * booking.people
                : packData.price[index].price * booking.people;
        const price =
            booking.transport === 'Without transport'
                ? packData.withoutTransportPrice
                : packData.price[index].price;
        setAmount((prevState) => {
            return { ...prevState, actualPrice: price, peopleAmo: peopleAmo };
        });
        //eslint-disable-next-line
    }, [booking.people, booking.startCity, booking.transport]);

    useEffect(() => {
        if (Object.keys(offerData).length !== 0) {
            console.log(Object.keys(offerData).length, 'inside');
            console.log('inside offer');
            const offerAmo = Math.floor(
                (((packData.price[index].price * 100) / 80) *
                    offerData.percentage) /
                    100 >
                    offerData.limit
                    ? offerData.limit
                    : (((packData.price[index].price * 100) / 80) *
                        offerData.percentage) /
                    100
            );
            setAmount((prevState) => {
                return { ...prevState, offerAmo: offerAmo };
            });
        }
        //eslint-disable-next-line
    }, [offerData]);

    useEffect(() => {
        const totalAmo = Math.floor(amount.peopleAmo - amount.offerAmo);
        console.log(totalAmo);
        setAmount((prevState) => {
            return { ...prevState, totalAmo: totalAmo };
        });
        //eslint-disable-next-line
    }, [amount.peopleAmo, amount.offerAmo]);

    //Booking tab  and amount data should be loaded if it existes in the bookingDate context
    //this can happen when user return from review-booking page
    useEffect(() => {
        if (Object.keys(bookingData).length !== 0) {
            setBooking({
                packDateId: bookingData.packDateId,
                title: bookingData.title,
                people: bookingData.people,
                date: bookingData.date,
                offerCode: bookingData.offerCode,
                startCity: bookingData.startCity,
                pickupLocation: bookingData.pickupLocation,
                transport: bookingData.transport
            });
            setAmount({
                actualPrice: bookingData.actualPrice,
                peopleAmo: bookingData.peopleAmo,
                offerAmo: bookingData.offerAmo,
                totalAmo: bookingData.totalAmo
            });
        }
    }, []);

    if (reviewPage) {
        // return (window.location.href = "/review-booking");
        //IF we use the above code the useContext state is getting cleared.
        return <Navigate to="/review-booking" />;
    }

    const toggleExpand = (e) => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div ref={bookingTabRef} className={`tripBookingTab ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <div className="tripBookingPriceDiv">
                <p className="tripBookingActualPrice">
                    Rs.{' '}
                    {booking.transport === 'Without transport'
                        ? (packData.withoutTransportPrice * 100) / 80
                        : (packData.price[index].price * 100) / 80}
                    /person
                </p>
                <p className="tripBookingPrice">
                    Rs.{' '}
                    {booking.transport === 'Without transport'
                        ? packData.withoutTransportPrice
                        : packData.price[index].price}
                    /person
                </p>
                <p className="tripBookingTaxInc">Including all taxes</p>
                <p className="tripBookingOffer">{20}%OFF</p>
                <button onClick={toggleExpand} className='collapseArrow'><Chevron width={24} height={24} /></button>
            </div>
            <div className="tripBookingSubTab">
                <div className="tripBookDataInput">
                    {/* <label className="tripBookDatalabelFirst">No. of People</label>
          <input
            // placeholder="People"
            type="number"
            max="6"
            min="1"
            value={booking.people}
            name="people"
            className="tripBookInput"
            onChange={(e) => handleChange(e, setBooking)}
          /> */}
                    <div className="dropdownDiv">
                        <label className="tripBookDatalabelFirst">
                            No. of People
                        </label>
                        <select
                            value={booking.people}
                            name="people"
                            onChange={(e) => handleChange(e, setBooking)}
                            required={true}
                            className="dropdownSelect"
                        >
                            {numPeople.map(function (val, typeIndex) {
                                return (
                                    <option key={typeIndex} value={val}>
                                        {val}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="dropdownDiv bookingDate">
                        <label className="tripBookDatalabel2">
                            Select Date
                        </label>
                        {/* <input
            type="date"
            value={booking.date}
            name="date"
            className="tripBookInput"
            onChange={(e) => handleChange(e, setBooking)}
          /> */}

                        <SingleDatePicker
                            date={booking.date}
                            onDateChange={(date) =>
                                setBooking((prevState) => {
                                    return { ...prevState, date: date };
                                })
                            }
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            numberOfMonths={1}
                            isDayBlocked={isDayBlocked}
                            isOutsideRange={(day) =>
                                day.isBefore(moment(), 'day')
                            } // Disallow past dates
                        />
                    </div>
                    {/* //the below p willl overlap the SingleDatePicker so that we can display date in indian formate */}
                    {/* <p onClick={() => setFocused(true)} className="tripBookDateDis">
            {booking.date ? booking.date.format("DD-MM-YYYY") : "Select Date"}
          </p> */}
                    {packData.withoutTransportPrice !== 0 && (
                        <Dropdown
                            label="Transport"
                            name="transport"
                            options={transport}
                            value={booking.transport}
                            req={true}
                            setFormData={setBooking}
                        />
                    )}
                    {booking.transport === 'With transport' && (
                        <>
                            <div className="dropdownDiv">
                                <label className="dropdownlabel">
                                    Start City
                                </label>
                                <select
                                    value={booking.startCity}
                                    name="startCity"
                                    onChange={(e) =>
                                        handleBaseCity(
                                            e,
                                            setBooking,
                                            startCity.current,
                                            setIndex
                                        )
                                    }
                                    required={true}
                                    className="dropdownSelect"
                                >
                                    <option value="-1">Select</option>
                                    {startCity.current.map(function (
                                        val,
                                        typeIndex
                                    ) {
                                        return (
                                            <option key={typeIndex} value={val}>
                                                {val}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <Dropdown
                                label="Pickup Location"
                                name="pickupLocation"
                                options={packData.price[index].pickupLocation}
                                value={booking.pickupLocation}
                                req={true}
                                setFormData={setBooking}
                            />
                        </>
                    )}

                    <label className="tripBookDatalabel2">Offer Code</label>
                    <div className="tripBookOfferDiv">
                        <input
                            type="text"
                            placeholder="Enter promo code"
                            name="offerCode"
                            value={offerCode}
                            className="tripBookOfferIn"
                            onChange={(e) => handlePromoCode(e)}
                        />
                        <span>{offerError}</span>
                        <button
                            className="tripBookOfferBtn"
                            onClick={() =>
                                offerApplyTrip(
                                    offerCode,
                                    setOfferData,
                                    setBooking
                                )
                            }
                        >
                            Apply
                        </button>
                    </div>
                </div>
                <div className="tripBookPriceDiv">
                    <div className="tripPriceCal">
                        <p className="tripBookPrice">
                            ₹
                            {booking.transport === 'Without transport'
                                ? packData.withoutTransportPrice
                                : packData.price[index].price}{' '}
                            x {booking.people}
                        </p>
                        <p className="tripBookPrice">₹{amount.peopleAmo}</p>
                    </div>
                    {Object.keys(offerData).length !== 0 && (
                        <div className="tripBookOfferDiv">
                            <p className="tripBookOfferText">Offer</p>
                            <p className="tripBookOfferText">
                                ₹{amount.offerAmo}
                            </p>
                        </div>
                    )}
                    <div className="tripBookTotalDiv">
                        <p className="tripBookTotalText">Total:</p>
                        <p className="tripBookTotalText">₹{amount.totalAmo}</p>
                    </div>
                </div>
                <div className="tripBookBtnDiv">
                    <button
                        className="tripBookingButton"
                        onClick={() =>
                            tripBookingClick(
                                booking,
                                setBookingData,
                                setReviewPage,
                                packData,
                                amount,
                                setLoading,
                                packDates
                            )
                        }
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    );
}
