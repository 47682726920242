import { useNavigate } from "react-router-dom";
import { fetchOffers } from "./fetchOffersFun/fetchOffers";
import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';

const OffersList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [offersData, setOffersData] = useState([]);
    console.log(offersData);

    useEffect(() => {
        fetchOffers(setLoading, setOffersData, limit, offset);
    }, [limit, offset]);
    return (
        <>
            <button onClick={() => navigate('/support/offers/upload')}>Add Offer</button>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
        </>
    );
};

export default OffersList;
