import HomeCarouselCard from "./homeCarouselCard";
import './homeCarousel.css';
import { ReactComponent as LeftInActive } from '../../assets/home/trvto_leftInActive.svg';
import { ReactComponent as RightActive } from '../../assets/home/trvto_RightActive.svg';
import { useRef } from "react";

const HomeCarousel = ({ data }) => {
    const carouselRef = useRef(null);
    const cardsPerSlide = 4;

    let slideWidth;
    if (carouselRef.current) {
        const containerWidth = carouselRef.current.scrollWidth;
        slideWidth = containerWidth / Math.ceil(carouselRef.current.children.length / cardsPerSlide);
    }
    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: -slideWidth,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: slideWidth,
                behavior: 'smooth',
            });
        }
    };
    return (
        <>
            <div className="carouselBtnWrapper">
                <button className="carouselBtn" onClick={scrollLeft}><LeftInActive /></button>
                <button className="carouselBtn active" onClick={scrollRight}><RightActive /></button>
            </div>
            <div className="destination-carousel-container" ref={carouselRef}>
                {data.map((item, index) => {
                    return <HomeCarouselCard key={index} data={item} />
                })}
            </div>
        </>
    );
};

export default HomeCarousel;
