import { useContext, useState } from 'react';
import handleChange from '../../../utils/handleChange';
import './register.css';
import emailValid from '../../../utils/emailValid';
import phoneValid from '../../../utils/phoneValid';
import { registerFun, regOTPFun } from '../../../api/userLoginAPI';
import { ReactComponent as CloseIcon } from '../../../assets/icons/trvto_close.svg';
import ReactLoading from 'react-loading';
import errorHandle from '../../../utils/errorHandle';
import { toast } from 'react-toastify';
import OTPInput from '../../otpinput/otpinput';
import { maskEmail, maskPhoneNumber } from '../../../utils/maskFields';
import { UserAuthContext } from '../../../context/UserAuthContext';

export default function Register(props) {
    const { setLogButton, setLoginSec } = props;
    const [userData, setUserData] = useState({
        name: '',
        emailId: '',
        countryCode: '+91',
        phoneNumber: '',
        emailOTP: '',
        phoneOTP: ''
    });
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [response, setResponse] = useState(false);
    const [loading, setLoading] = useState(false);

    const { login } = useContext(UserAuthContext);

    //close the pop up and set regSec to reg
    function close() {
        setLogButton(false);
        setLoginSec('login');
    }

    //function to handle clicks on the register button
    async function handleRegister() {
        if (!phoneValid(userData.phoneNumber)) {
            setPhoneError('Please enter a valid phone number');
            return;
        }
        setPhoneError('');

        if (!emailValid(userData.emailId)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        setEmailError('');
        setLoading(true);

        try {
            if (!response) {
                //generate OTP to create account
                const genOTP = await regOTPFun(userData);
                console.log(genOTP);
                setResponse(genOTP.status);
                setLoading(false);
                toast.success(
                    `Please enter the OTP sent to your Email: ${userData.emailId} and Phone number: ${userData.phoneNumber}`
                );
            } else if (response) {
                //create account with OTP
                const res = await registerFun(userData);
                console.log(res);
                setLoading(false);
                toast.success(
                    `Successfully created your account and loged in `
                );
                login(res.data);
                close();
                // setLoginSec('login');
            }
        } catch (error) {
            errorHandle(error);
            setLoading(false);
        }
    }

    return (
        <div className="reg">
            {loading && (
                <div className="loadingBackgroundCustom">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <h1 className="regH1">
                {!response ? 'Sign Up' : 'OTP Verification'}
            </h1>
            <button type="button" onClick={close} className="regCancel">
                <CloseIcon className="regCloseIcon" />
            </button>
            {/* {response !== '200' && ( */}
            <div className="regInputDiv">
                <label>Name</label>
                <input
                    placeholder="Name"
                    name="name"
                    onChange={(e) => handleChange(e, setUserData)}
                    value={userData.name}
                    className="regInput"
                />
                <label>Email</label>
                <input
                    placeholder="Email"
                    type="email"
                    name="emailId"
                    onChange={(e) => handleChange(e, setUserData)}
                    value={userData.emailId}
                    className="regInput"
                />
                {emailError && <span className="regError">{emailError}</span>}
                {response && (
                    <div className="regInputDiv">
                        <p>
                            Verification code has been sent to your email{' '}
                            {maskEmail(userData.emailId)}, please enter it here
                            to complete the signup.
                        </p>
                        <label>Email OTP</label>
                        <OTPInput
                            length={6}
                            setUserData={setUserData}
                            keyName={'emailOTP'}
                        />
                    </div>
                )}
                <label>Phone Number</label>
                <input
                    placeholder="Number"
                    type="text"
                    name="phoneNumber"
                    onChange={(e) => handleChange(e, setUserData)}
                    value={userData.phoneNumber}
                    className="regInput"
                />
                {phoneError && <span className="regError">{phoneError}</span>}
                {response && (
                    <div className="regInputDiv">
                        <p>
                            Verification code has been sent to your phone number
                            {maskPhoneNumber(userData.phoneNumber)}, please
                            enter it here to complete the signup.
                        </p>
                        <label>Phone OTP</label>
                        <OTPInput
                            length={6}
                            setUserData={setUserData}
                            keyName={'phoneOTP'}
                        />
                    </div>
                )}
                <h2 onClick={() => setLoginSec('login')} className="regForgot">
                    Login
                </h2>
            </div>
            {/* )} */}

            <button type="button" onClick={handleRegister} className="regBtn">
                {!response ? 'Confirm' : 'Sign Up'}
            </button>
        </div>
    );
}
