import ReviewStars from "../reviewStars/ReviewStars";
import ReviewBgBottomPiece from '../../assets/home/trvto_reviewBgPiece.png';
import ReviewBgTopPiece from '../../assets/home/trvto_reviewTopPiece.png';
import ConnectingDots from '../../assets/home/trvto_connection.png';

const ReviewCard = ({ data }) => {
    return (
        <div className="review-carousel-item">
            <img src={data.image} alt={data.title} />
            <img className="connectingDots" src={ConnectingDots} alt='trvto_connecting dots' />
            <div>
                <img className="topPiece" src={ReviewBgTopPiece} alt='trvto_blow' />
                <ReviewStars rating={data.rating} />
                <p className="reviewDetail">{data.description}</p>
                <p className="author">{data.author}</p>
                <p className="designation">{data.designation}</p>
                <img className="bottomPiece" src={ReviewBgBottomPiece} alt='trvto_Quote' />
            </div>
        </div>
    );
}

export default ReviewCard;
