import Routing from '../assets/icons/trvto_routing.svg';
import Messages from '../assets/icons/trvto_messages.svg';
import Note from '../assets/icons/trvto_note.svg';
import Timer from '../assets/icons/trvto_timer.svg';
import Achievement from '../assets/icons/trvto_achievement.svg';
import Truck from '../assets/icons/trvto_truck.svg';
import Places from '../assets/home/trvto_places.png';
import Travel from '../assets/home/trvto_travel.png';
import Explore from '../assets/home/trvto_explore.png';
import One from '../assets/home/trvto_One.svg';
import Two from '../assets/home/trvto_Two.svg';
import Three from '../assets/home/trvto_Three.svg';
import Four from '../assets/home/trvto_Four.svg';
import Switzerland from '../assets/home/trvto_Switzerland.png';
import Brazil from '../assets/home/trvto_Brazil.png';
import France from '../assets/home/trvto_France.png';
import Singapore from '../assets/home/trvto_Singapore.png';
import Italy from '../assets/home/trvto_Italy.png';
import Dubai from '../assets/home/trvto_Dubai.png';
import FirstBooking from '../assets/home/trvto_FirstBooking.png';

export const WhyUs = [
    {
        id: 1,
        icon: Routing,
        title: 'Diverse Experiences',
        color: '#6056DE',
        background: '#F1F0FF'
    },
    {
        id: 2,
        icon: Messages,
        title: 'Expert Guides',
        color: '#FF9400',
        background: '#FFF9F0'
    },
    {
        id: 3,
        icon: Note,
        title: 'Safety First',
        color: '#3B9C4D',
        background: '#E9FFED'
    },
    {
        id: 4,
        icon: Timer,
        title: 'Authentic and Raw Experience',
        color: '#007AFE',
        background: '#EAF4FF'
    },
    {
        id: 5,
        icon: Achievement,
        title: 'One-Stop Adventure Hub',
        color: '#C15288',
        background: '#FCDFED'
    },
    {
        id: 6,
        icon: Truck,
        title: 'Wide Selection of Organizers',
        color: '#2192A2',
        background: '#DBF3F6'
    }
];

export const DiscountCards = [
    {
        id: 1,
        image: Places,
        description: 'Places'
    },
    {
        id: 2,
        image: Travel,
        description: 'Travel'
    },
    {
        id: 3,
        image: Explore,
        description: 'Explore'
    }
];

export const Counters = [
    {
        id: 1,
        image: One,
        number: 532,
        title: 'Organizers'
    },
    {
        id: 2,
        image: Two,
        number: 532,
        title: 'Itineraries '
    },
    {
        id: 3,
        image: Three,
        number: 532,
        title: 'Successful Trips'
    },
    {
        id: 4,
        image: Four,
        number: 532,
        title: '5 Star Reviews'
    }
];

export const ExoticPlaces = [
    {
        id: 1,
        image: Switzerland,
        count: 3,
        title: 'Switzerland'
    },
    {
        id: 2,
        image: Brazil,
        count: 3,
        title: 'Brazil'
    },
    {
        id: 3,
        image: France,
        count: 3,
        title: 'France'
    },
    {
        id: 4,
        image: Singapore,
        count: 4,
        title: 'Singapore'
    },
    {
        id: 5,
        image: Italy,
        count: 7,
        title: 'Italy'
    },
    {
        id: 6,
        image: Dubai,
        count: 8,
        title: 'Dubai'
    },
    {
        id: 7,
        image: FirstBooking,
        count: 8,
        title: 'Dubai'
    }
];

export const SeoLinks = [
    {
        name: 'Product Offering',
        link: 'https://www.google.com'
    },
    {
        name: 'Makemytrip',
        link: 'https://www.google.com'
    },
    {
        name: 'About the site',
        link: 'https://www.google.com'
    },
    {
        name: 'Top Hotels in India',
        link: 'https://www.google.com'
    },
    {
        name: 'Quick Links',
        link: 'https://www.google.com'
    },
    {
        name: 'Book Hotels in India From Top Destinations',
        link: 'https://www.google.com'
    },
    {
        name: 'Top International Hotels',
        link: 'https://www.google.com'
    },
    {
        name: 'Important Links',
        link: 'https://www.google.com'
    },
    {
        name: 'Corporate Travel',
        link: 'https://www.google.com'
    }
];