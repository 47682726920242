import https from "../init/https";

//BLOG-01 : Get many different blogs to populate blog home page
export async function blogFun() {
  const result = await https.get(`/blog/home`);
  return result;
}

//BLOG-02 : Get complete content of blog based blog id
export async function blogContentFun(blogId) {
  const result = await https.get(`/blog/details/${blogId}`);
  return result;
}
