import "./blogDetails.css";
import BannerCarousel from "../../components/bannerCarousel/BannerCarousel";
import BannerImageData from "../../dummyData/bannerCarouselData.json";
import BlogRelated from "../../components/blogRelated/BlogRelated";
import TitleBar from "../../components/titleBar/titleBar";
import { ReactComponent as UserImg } from "../../assets/icons/trvto_avatar.svg";
import BlogCard from "../../components/blogCard/BlogCard";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import fetchBlogDetailData from "./fetchBlogDataFun/fetchBlogData";
import ReactLoading from "react-loading";
import fetchBlogsData from "../blog/fetchBlogsFun/fetchBlogsData";

export default function BlogDetails() {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBlogDetailData(id, setBlogDetails, setLoading);
  }, [id]);

  useEffect(() => {
    fetchBlogsData(setBlogData, setLoading);
  }, []);

  const imageData = [{
    url: blogDetails?.image,
    alt: blogDetails?.name
  }];

  return (
    <div>
      <BannerCarousel className="blogCarousel" imageData={imageData} />
      <TitleBar title={blogDetails?.name} className='customTitle' />
      <div className="customContainer blogDetailsBody">
        <div className="blogDetailsContent">
          <div className="blogPostContent">
            <h2>Short description</h2>
            <p>{blogDetails?.shortDescription}</p>
            {blogDetails?.description?.map((item, index) => (
              <div key={index}>
                <h2>{item?.title}</h2>
                {item?.description.map((ele, index) => (
                  <div key={index}>
                    <p>{ele}</p>
                  </div>
                ))}
                <img height={320} src={item?.image} alt={item?.title} />
              </div>
            ))}
          </div>

          <div className="blogAuthorInfo">
            <UserImg className="authorImage" />
            <div className="authorDetails">
              <h3>{blogDetails?.author}</h3>
              <p>{new Date(blogDetails?.createdAt).toLocaleString()}</p>
            </div>
          </div>

          <div>
            <h3>Related Posts</h3>
            <div className="relatedPosts">
              {blogData.slice(0, 3).map((blog, blogIndex) => {
                return <BlogCard blogData={blog} key={blogIndex} />;
              })}
            </div>
          </div>

          {/* <div className="blogComments">
            <h3>Comments</h3>
            <div className="commentForm">
              <textarea placeholder="Write your comment..." />
              <button type="submit">Post Comment</button>
            </div>
          </div> */}
        </div>
        {/* <BlogRelated /> */}
        {loading && (
          <div className="loadingBackground">
            <ReactLoading type="spin" color="#006aff" className="loadingSpin" />
          </div>
        )}
      </div>
    </div>
  );
}
