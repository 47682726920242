import Analytics from '../../assets/icons/trvto_analytics.svg';
import Bookings from '../../assets/icons/trvto_bookings.svg';
import Dashboard from '../../assets/icons/trvto_dashboard.svg';
import Insights from '../../assets/icons/trvto_insights.svg';
import MyPackages from '../../assets/icons/trvto_my_packages.svg';
import MyRooms from '../../assets/icons/trvto_my_rooms.svg';
import Reviews from '../../assets/icons/trvto_reviews.svg';
import Tickets from '../../assets/icons/trvto_tickets.svg';
import Payments from '../../assets/icons/trvto_payments.svg';
import Promotions from '../../assets/icons/trvto_promotions.svg';
import User from '../../assets/icons/trvto_userManagement.svg';
import CustomerSupport from '../../assets/icons/trvto_contact.svg';
import DashboardActive from '../../assets/icons/trvto_dashboardActive.svg';
import MyPackagesActive from '../../assets/icons/trvto_myPackagesActive.svg';
import AnalyticsActive from '../../assets/icons/trvto_analyticsActive.svg';
import MyRoomsActive from '../../assets/icons/trvto_myRoomsActive.svg';
import ReviewActive from '../../assets/icons/trvto_ReviewActive.svg';
import BookingActive from '../../assets/icons/trvto_BookingActive.svg';
import InsightsActive from '../../assets/icons/trvto_insightsActive.svg';
import CustomerSupportActive from '../../assets/icons/trvto_custSupportActive.svg';
import TicketsActive from '../../assets/icons/trvto_ticketsActive.svg';
import PaymentsActive from '../../assets/icons/trvto_paymentsActive.svg';
import PromotionsActive from '../../assets/icons/trvto_promotionsActive.svg';
import UserActive from '../../assets/icons/trvto_supUserActive.svg';

export const LeftMenuData = [
    // {
    //     title: 'Dashboard',
    //     path: '/support/assistance',
    //     icon: Dashboard,
    //     activeIcon: DasboardActive
    // },
    // {
    //     title: 'My Packages',
    //     path: '/support/assistance',
    //     icon: MyPackages,
    //     activeIcon: MyPackagesActive
    // },
    {
        title: 'Tickets',
        path: '/support/tickets',
        icon: Tickets,
        activeIcon: TicketsActive
    },
    {
        title: 'Blogs',
        path: '/support/blogs',
        icon: MyRooms,
        activeIcon: MyRoomsActive
    },
    {
        title: 'Offers',
        path: '/support/offers',
        icon: Bookings,
        activeIcon: BookingActive
    },
    {
        title: 'Dropdowns',
        path: '/support/dropdowns',
        icon: Dashboard,
        activeIcon: DashboardActive
    },
    {
        title: 'Payments',
        path: '/support/assistance',
        icon: Payments,
        activeIcon: PaymentsActive
    },
    {
        title: 'Approvals',
        path: '/support/assistance',
        icon: Reviews,
        activeIcon: ReviewActive
    },
    {
        title: 'Promotions',
        path: '/support/assistance',
        icon: Promotions,
        activeIcon: PromotionsActive
    },
    {
        title: 'User Management',
        path: '/support/assistance',
        icon: User,
        activeIcon: UserActive
    },
    {
        title: 'Customer Support',
        path: '/support/assistance',
        icon: CustomerSupport,
        activeIcon: CustomerSupportActive
    },
    {
        title: 'Reporting',
        path: '/support/assistance',
        icon: Insights,
        activeIcon: InsightsActive
    },
    {
        title: 'Analytics',
        path: '/support/assistance',
        icon: Analytics,
        activeIcon: AnalyticsActive
    },
    {
        title: 'Reviews',
        path: '/support/assistance',
        icon: Reviews,
        activeIcon: ReviewActive
    }
];
