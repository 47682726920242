import { toast } from 'react-toastify';

export default function ImageUpOrg({ imgData, setImgData }) {
    function handelWallImage(e) {
        if (e.target.files[0]) {
            //Without conversiotion to array it we cant store or read it as array
            const selectedFiles = Array.from(e.target.files);
            const sizeLimit = 2 * 1024 * 1024; // 2 MB in bytes
            // Number of images should be less than 11
            if (selectedFiles > 10) {
                toast.error('You can select only upto 10 images');
                return;
            }
            // check if all the files are less than 2mb
            const checkImg = selectedFiles.every((img) => {
                return img.size > sizeLimit;
            });
            if (checkImg) {
                toast.error('Each Image size should be less than 2 mb');
                return;
            }
            setImgData((prevState) => {
                return { ...prevState, wallImg: selectedFiles };
            });
        } else {
            setImgData((prevState) => {
                return { ...prevState, wallImg: '' };
            });
        }
    }

    function handelThumbImage(e) {
        if (e.target.files[0]) {
            //Without conversiotion to array it we cant store or read it as array
            const selectedFiles = Array.from(e.target.files);
            const sizeLimit = 500 * 1024; // 500kB in bytes
            // Number of images should be less than 6
            if (selectedFiles.length > 5) {
                toast.error('You can select only upto 5 images');
                return;
            }
            // check if all the files are less than 100kb
            const checkImg = selectedFiles.every((img) => {
                return img.size > sizeLimit;
            });
            if (checkImg) {
                toast.error('Each Image size should be less than 500 kb');
                return;
            }
            setImgData((prevState) => {
                return { ...prevState, thumbImg: selectedFiles };
            });
        } else {
            setImgData((prevState) => {
                return { ...prevState, thumbImg: '' };
            });
        }
    }

    return (
        <div>
            <h2 className="orgPackUpH2">Images</h2>
            {/* image upload div */}
            <div className="orgPackUpSecDiv">
                <div className="itineraryInDiv">
                    <label className="itineraryLabel">
                        Wallpaper Images (upto 10)
                    </label>
                    <div className="itineraryInputDiv">
                        <input
                            type="file"
                            multiple
                            accept=".jpg, .jpeg, .png"
                            className="itineraryInput"
                            onChange={(e) => handelWallImage(e)}
                        />
                    </div>
                </div>
                {imgData.wallImg.length !== 0 && (
                    <div className="imgDisplayDiv">
                        {imgData.wallImg.map((upImg, imgKey) => {
                            //to display the image convert it to URL
                            const fileURL = URL.createObjectURL(upImg);
                            return (
                                <img
                                    src={fileURL}
                                    alt="trvto_package"
                                    key={imgKey}
                                    className="wallImgDisp"
                                />
                            );
                        })}
                    </div>
                )}
                <div className="itineraryInDiv">
                    {/* small image upload div */}
                    <label className="itineraryLabel">
                        Thumbnail Images (upto 6)
                    </label>
                    <div className="itineraryInputDiv">
                        <input
                            type="file"
                            multiple
                            accept=".jpg, .jpeg, .png"
                            className="itineraryInput"
                            onChange={(e) => handelThumbImage(e)}
                        />
                    </div>
                </div>
                {imgData.thumbImg.length !== 0 && (
                    <div className="imgDisplayDiv">
                        {imgData.thumbImg.map((upImg, imgKey) => {
                            //to display the image convert it to URL
                            const fileURL = URL.createObjectURL(upImg);
                            return (
                                <img
                                    src={fileURL}
                                    alt="trvto_package"
                                    key={imgKey}
                                    className="wallImgDisp"
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
