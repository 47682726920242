import {
    FormContent,
    SummaryItem,
} from '../../components/bookingConfirmationCard/bookingConfirmationFun';

import BookingConfirmationCard from '../../components/bookingConfirmationCard/bookingConfirmationCard';
import TitleBar from '../../components/titleBar/titleBar';
import holidayImage from '../../assets/images/trvto_holiday.png';
import tripImage from '../../assets/images/trvto_trip.png';

const BookingConfirmation = () => (
    <>
        <TitleBar title="Booking Confirmation" />
        <div className="manageOrder">
            <div className="customContainer">
                <div className="cardContainer">
                    <BookingConfirmationCard
                        title="Your Trip"
                        imgSrc={tripImage}
                        imgAlt="trvto_trip"
                        tripName="Pongan Hotel & Resort"
                        reviews={{ rating: '4.5', count: '237' }}
                    >
                        <FormContent
                            label="Dates"
                            answer="10 August, 2024 - 16 August, 2024"
                        />
                        <div className="innerFormContent">
                            <FormContent label="Guests" answer="4" />
                            <FormContent label="Rooms" answer="2" />
                        </div>
                        <div className="innerFormContent">
                            <FormContent
                                label="Check In Time"
                                answer="09:00 AM"
                                className="borderNone"
                            />
                            <FormContent
                                label="Check Out Time"
                                answer="08:00 PM"
                                className="borderNone"
                            />
                        </div>
                    </BookingConfirmationCard>
                    <div className="card rightCard">
                        <p className="title font24">Summary</p>
                        <div className="summaryContent">
                            <SummaryItem label="Sub Total" amount="$ 11.00" />
                            <SummaryItem label="Tax" amount="$ 2.00" />
                            <SummaryItem label="Shipping" amount="$ 1.00" />
                            <SummaryItem
                                label="Total"
                                amount="$ 13.00"
                                isTotal={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="customContainer" style={{ marginTop: '24px' }}>
                <div className="cardContainer">
                    <BookingConfirmationCard
                        title="Holiday Packages"
                        imgSrc={holidayImage}
                        imgAlt="trvto_holiday"
                        tripName="Jeju Island"
                        reviews={{ rating: '4.5', count: '237' }}
                    >
                        <FormContent
                            label="Dates"
                            answer="10 August, 2024 - 16 August, 2024"
                        />
                        <div className="innerFormContent">
                            <FormContent
                                label="Pickup Timings"
                                answer="11:00 AM"
                            />
                            <FormContent label="Guests" answer="2" />
                        </div>
                        <div className="innerFormContent">
                            <FormContent label="Type" answer="Holiday" />
                            <FormContent label="Stay" answer="5 Days" />
                        </div>
                        <div className="innerFormContent">
                            <FormContent label="Food" answer="Yes" />
                            <FormContent
                                label="Transport"
                                answer="Indigo Air"
                            />
                        </div>
                        <FormContent
                            label="Pickup Location"
                            answer="2464 Royal Ln. Mesa, New Jersey 45463"
                        />
                        <FormContent
                            label="Organizer Name"
                            answer="Jaane Cooper"
                            className="borderNone"
                        />
                    </BookingConfirmationCard>
                </div>
            </div>
        </div>
    </>
);

export default BookingConfirmation;
