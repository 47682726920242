import MobileCarousel from "../../../components/mobileCarousel/mobileCarousel";
import { ExoticPlaces } from "../../../dummyData/homePage";
import './feature.css';

const FeatureSection = () => {
    return (
        <div className="customContainer featuresSection">
            <div style={{ textAlign: 'center' }}>
                <p className='pageCount'>02 Features</p>
                <p className="homeTitle">Go Exotic Places</p>
            </div>

            <div className="featureWrapper mobHidden">
                {ExoticPlaces.slice(0, 3).map((item) => {
                    return (
                        <div key={item.id} className="feature">
                            <img src={item.image} alt={item.title} />
                            <span className="featureCount">{item.count} Tours</span>
                            <span className="featureTitle">{item.title}</span>
                        </div>
                    )
                })}
            </div>

            <div className="featureWrapperRowTwo mobHidden">
                {ExoticPlaces.slice(3, 7).map((item, index) => {
                    if (index < 3) {
                        return (
                            <div key={item.id} className="feature">
                                <img src={item.image} alt={item.title} />
                                <span className="featureCount">{item.count} Tours</span>
                                <span className="featureTitle">{item.title}</span>
                            </div>
                        )
                    } else {
                        return (
                            <div key={item.id} className="feature featureBorder">
                                <img src={item.image} alt={item.title} />
                                <span className="featureCount firstTimeDiscount">UP TO 50% Off</span>
                                <span className="firstBookingTitle">Your <br /> First Booking</span>
                                <button className="viewDetailsBtn">View Details</button>
                            </div>
                        )
                    }
                })}
            </div>
            <div className="mobDisplay">
                <MobileCarousel type='feature' data={ExoticPlaces} />
            </div>
        </div>
    );
}

export default FeatureSection;