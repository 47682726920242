import './titleBar.css';

import titleBannerImage from '../../assets/images/trvto_calendar.png';
import titleBannerTile from '../../assets/images/trvto_titleBanner.svg';

const TitleBar = ({ title, className = '' }) => {
    return (
        <div className={`titleBanner alignCenter ${className}`}>
            <p className="title font26">{title}</p>
            <img
                className="titleBannerPiece"
                src={titleBannerTile}
                alt="trvto_tile"
            />
            <img
                className='titleBannerImage'
                src={titleBannerImage}
                alt="trvto_calendar"
            />
        </div>
    );
};

export default TitleBar;
