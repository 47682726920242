import Filters from '../../components/filters/Filters';
import { ReactComponent as CloseIcon } from "../../assets/icons/trvto_close.svg";
import './mobileFilter.css';

const MobileFilter = ({ filterData, onFilterChange, handleClose, showModal }) => {
    return (
        <>
            <div className="modal searchFilterModal">
                <div className="modal-content">
                    <div className="modal-header" onClick={handleClose}>
                        <CloseIcon style={{ width: '24px', height: '24px' }} />
                    </div>
                    <Filters filterData={filterData} onFilterChange={onFilterChange} />
                    <button
                        type="button"
                        onClick={handleClose}
                        className="loginBtn"
                    >
                        Apply
                    </button>
                </div>
            </div>
            {showModal && <div className='overlay filterOverlay' />}
        </>
    );
};

export default MobileFilter;
