import './home.css';
import BannerImageData from '../../dummyData/bannerCarouselData.json';
import TabsBar from '../../webPageSections/tabsBar/TabsBar';
import BannerCarousel from '../../components/bannerCarousel/BannerCarousel';
import LetsGo from '../../assets/images/trvto_letsgo.svg';
import { useState } from 'react';
import WhyUsSection from './whyus/whyUs';
import DiscountSection from './discount/discount';
import CounterSection from './counter/counter';
import FeatureSection from './feature/feature';
import DestinationSection from './destination/destination';
import ReviewSection from './review/review';
import BlogSection from './blog/blog';
import SeoSection from './seo/seo';
import Holiday from '../../webPageSections/tabsBar/searchComponents/holiday';
import Camp from '../../webPageSections/tabsBar/searchComponents/camp';
import AdventureSection from './adventure/adventure';

function Home() {
    const [selectOption, setSelectOption] = useState('Holiday');

    const handleType = (e) => {
        setSelectOption(e.target.value);
    };

    return (
        <div>
            <BannerCarousel
                className="homePage"
                imageData={BannerImageData?.data}
            />

            <div className="bannerWrapper mobHidden">
                <div className="bannerTitle">
                    <img src={LetsGo} alt="trvto_lets go" />
                    <h1>Where Would You Like To Go?</h1>
                </div>
                <TabsBar />
            </div>
            <div className="mobDisplay">
                <div className="customContainer">
                    <div className="searchCard">
                        <select
                            value={selectOption}
                            name="type"
                            onChange={(e) => handleType(e)}
                            className="homeSearch"
                        >
                            <option value="Select">Select</option>
                            <option value="Holiday">Holiday</option>
                            <option value="Camp" disabled>
                                Camp
                            </option>
                            <option value="Guide" disabled>
                                Guide
                            </option>
                        </select>
                        {(selectOption === 'Holiday' ||
                            selectOption === 'Guide') && <Holiday />}
                        {selectOption === 'Camp' && <Camp />}
                    </div>
                </div>
            </div>
            <DestinationSection />
            <FeatureSection />
            <AdventureSection />
            <DiscountSection />
            <WhyUsSection />
            {/* <CounterSection /> */}
            <ReviewSection />
            <BlogSection />
            <SeoSection />
        </div>
    );
}

export default Home;
