import Logo from '../../../assets/images/trvto-logo.png';
export default function OrgInfo({ accDetails }) {
    return (
        <div>
            <h2 className="orgAccountH2Own">Profile</h2>
            <div className="orgAccountSecDiv">
                <div className="profileDiv">
                    <div>
                        <img
                            src={accDetails.logo || Logo}
                            width={120}
                            height={50}
                            alt='trvto_logo'
                        />
                    </div>
                    <div className="w-full">
                        <div className="orgAccountInDiv">
                            <p className="orgAccounProfiletp">
                                {accDetails.companyName}
                            </p>
                        </div>
                        <div className="orgAccountInDiv mt-2">
                            <span className="orgAccountLabel">
                                {accDetails.typeofCompany}
                            </span>
                        </div>
                        <div className="orgAccountInDiv mt-2">
                            <span className="orgAccountLabel">
                                {accDetails.emailId}
                            </span>
                        </div>
                        <div className="orgAccountInDiv mt-2">
                            <span className="orgAccountLabel">
                                {accDetails.address.state},
                                {accDetails.address.country}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
