import handleChange from '../../utils/handleChange';
import handleNestedChange from '../../utils/handleNestedChange';
import './dropdownReg.css';

export default function DropdownReg({
    label,
    name,
    options,
    value,
    req,
    setFormData,
    nest
}) {
    return (
        <div className="dropRegDiv">
            <label className="dropRegLabel">{label}</label>
            <div className="dropRegInputDiv">
                <select
                    value={value}
                    name={name}
                    onChange={
                        nest
                            ? (e) => handleNestedChange(e, setFormData, nest)
                            : (e) => handleChange(e, setFormData)
                    }
                    required={req}
                    className="dropRegSelect"
                >
                    <option value="-1">Select</option>
                    {options.map(function (val, typeIndex) {
                        return (
                            <option key={typeIndex} value={val}>
                                {val}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}
