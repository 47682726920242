import './aboutUs.css';
import { WhyUs } from '../../dummyData/homePage';
import TitleBar from '../../components/titleBar/titleBar';

export default function AboutUs() {
    return (
        <>
            <TitleBar title="About us" className="customTitle" />
            <div className="customContainer aboutus-container">
                <p className="homeTitle">
                    Welcome to TrvTo - Unleash the Adventurer in You!
                </p>
                <div className="innerGrid" style={{ margin: '20px 0 30px' }}>
                    {WhyUs.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className="whyUsGrid"
                                style={{ backgroundColor: item.background }}
                            >
                                <img src={item.icon} alt={item.title} />
                                <p
                                    style={{
                                        color: item.color,
                                        fontWeight: 600
                                    }}
                                >
                                    {item.title}
                                </p>
                            </div>
                        );
                    })}
                </div>
                <p className="description">
                    At TrvTo, we believe that travel should be an exhilarating
                    blend of adventure, raw experiences, and utmost safety. We
                    are your ultimate platform for booking unforgettable
                    holidays, thrilling adventure experiences such as camping
                    and trekking, and much more. Our mission is to provide you
                    with not just a trip, but a journey that you'll cherish
                    forever.
                </p>
                <p className="description">
                    We are passionate about creating a perfect balance between
                    adventure and safety. Our curated experiences are designed
                    to let you explore the wild side of nature while ensuring
                    your well-being. Whether you're seeking the serenity of
                    nature or the excitement of an adrenaline-fueled trek, or
                    enjoy camping trip, or immerse yourself in the rich history
                    of a place, we've got you covered.
                </p>
                <b>Our Mission</b>
                <p className="description">
                    "Our mission is to offer our customers raw, authentic, and
                    safe adventure experiences. We understand that true
                    adventure lies in genuine exploration, and we strive to
                    provide that while ensuring the highest standards of safety.
                    At TrvTo, your safety and enjoyment are our top priorities.
                    Our team of experts rigorously checks and verifies all
                    experiences to provide you with peace of mind as you explore
                    the unknown."
                </p>
                <b>Why Choose TrvTo?</b>
                <ul>
                    <li>
                        <span>Diverse Experiences:</span> From mountain treks to
                        historic place explorations, our platform offers a wide
                        range of adventure activities tailored to satisfy every
                        traveller’s thirst for adventure.
                    </li>
                    <li>
                        <span>Expert Guides:</span> We understand that the right
                        guidance can make or break your travel experience.
                        Enhance your experience with our knowledgeable guides.
                        Whether you're trekking through rugged terrains,
                        exploring historical sites, or navigating a new city,
                        our guides are here to make your journey informative and
                        memorable. We ensure your safety by offering detailed
                        information about your guide.
                    </li>
                    <li>
                        <span>Safety First:</span> We prioritize your safety in
                        every experience we offer. Our team ensures that all
                        activities meet stringent safety standards, providing
                        you with peace of mind as you embark on your adventure.
                    </li>
                    <li>
                        <span>Authentic and Raw Experience:</span> We believe
                        that the most memorable journeys are those that take you
                        off the beaten path, where the true essence of a
                        destination shines through. Our authentic and raw
                        experiences are designed to immerse you in the heart of
                        nature and culture, far from the typical tourist trails.
                        Whether it’s camping under a blanket of stars in a
                        remote wilderness, trekking through uncharted terrain,
                        we ensure that every moment is genuine and unfiltered.
                        Experience the world as it truly is.
                    </li>
                    <li>
                        <span>One-Stop Adventure Hub:</span> We aim to be your
                        go-to platform for all adventure experiences. With
                        comprehensive travel guidance and booking options,
                        planning your next adventure has never been easier.
                    </li>
                    <li>
                        <span>Wide Selection of Organizers:</span> We offer a
                        diverse range of organizers, each providing unique trips
                        to the same destinations. Find and choose the organizer
                        that best matches your preferences, ensuring a
                        personalized and tailored travel experience.
                    </li>
                </ul>
                <b>What We Offer</b>
                <ul>
                    <li>
                        <span>Holiday Bookings:</span> From thrilling mountain
                        adventures to serene beachfront escapes, we offer a wide
                        range of holiday options that suit every taste and
                        budget.
                    </li>
                    <li>
                        <span>Guided Activities:</span> Enhance your experience
                        with our knowledgeable guides who are experts in
                        trekking, historical exploration, city tours, and more.
                        They are here to ensure you make the most of your
                        adventure, providing insights and guidance every step of
                        the way.
                    </li>
                    <li>
                        <span>Camping:</span> Ever dreamed of spending the night
                        under the stars, surrounded by nature in a tent on a
                        beach or in the wilderness? Now’s your chance! Enjoy a
                        cozy campfire to keep you warm, delicious meals served
                        at the attached restaurants, with convenient charging
                        options available to keep your devices powered up.
                    </li>
                </ul>
                <div className="joinus-description">
                    <p>
                        Join us at TrvTo and let's make your next holiday an
                        adventure to remember and embark on a journey filled
                        with raw nature, discovery, and safety.
                    </p>
                    <p>
                        Explore, experience, and enjoy the thrill of adventure
                        with us.
                    </p>
                </div>
            </div>
        </>
    );
}
