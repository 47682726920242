import Amazon from '../../../assets/icons/trvto_amazon.png';
import Amex from '../../../assets/icons/trvto_amex.png';
import Facebook from '../../../assets/icons/trvto_facebook.svg';
import Youtube from '../../../assets/icons/trvto_youtube.svg';
import Instagram from '../../../assets/icons/trvto_instagram.svg';
import LinkedIn from '../../../assets/icons/trvto_linkedin.svg';
import MasterCard from '../../../assets/icons/trvto_masterCard.png';
import Paypal from '../../../assets/icons/trvto_paypal.png';
import Visa from '../../../assets/icons/trvto_visa.png';
import X from '../../../assets/icons/trvto_x.svg';

export const socialIcons = [
    {
        name: 'Facebook',
        icon: Facebook,
        link: 'https://www.facebook.com/people/TrvTo/61563067058686/'
    },
    {
        name: 'X',
        icon: X,
        link: 'https://x.com/TrvTo_official'
    },
    {
        name: 'LinkedIn',
        icon: LinkedIn,
        link: 'https://www.linkedin.com/company/trvto'
    },
    {
        name: 'Youtube',
        icon: Youtube,
        link: 'https://www.youtube.com/@TrvTo'
    },
    {
        name: 'Instagram',
        icon: Instagram,
        link: 'https://www.instagram.com/trvto_official/'
    }
];

export const quickLinks = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'About Us',
        link: '/about-us'
    },
    {
        name: 'Destinations',
        link: '/'
    },
    {
        name: 'Services',
        link: '/'
    },
    {
        name: 'Reviews',
        link: '/'
    },
    {
        name: 'Manage Booking',
        link: '/my-booking'
    },
    {
        name: 'FAQ',
        link: '/faq'
    }
];

export const whoWeAre = [
    {
        name: 'Contact Us',
        link: '/contact-us'
    },
    {
        name: 'Blog',
        link: '/blog'
    },
    {
        name: 'Help',
        link: '/'
    }
];

export const legal = [
    {
        name: 'Terms of Service',
        link: '/terms'
    },
    {
        name: 'Privacy Policy',
        link: '/privacy-policy'
    },
    {
        name: 'Cancellation Policy',
        link: '/cancellation-policy'
    }
];

export const paymentDetails = [
    {
        name: 'Visa',
        icon: Visa
    },
    {
        name: 'Master Card',
        icon: MasterCard
    },
    {
        name: 'Paypal',
        icon: Paypal
    },
    {
        name: 'Amazon',
        icon: Amazon
    },
    {
        name: 'Amex',
        icon: Amex
    }
];
