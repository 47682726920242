import { toast } from 'react-toastify';
import { orgSendMessage } from '../../../api/orgTripAPI';
import errorHandle from '../../../utils/errorHandle';

export const sendMessage = (messageData, setLoading) => {
    setLoading(true);
    try {
        if (messageData.message.length < 10) {
            toast.error('The message must be more than 10 characters.');
            setLoading(false);
        } else if (messageData.message.length > 50) {
            toast.error('The message must be less than 50 characters.');
            setLoading(false);
        } else {
            orgSendMessage(messageData).then((res) => {
                if (res.status === 200) {
                    toast.success('Message sent successfully');
                } else {
                    toast.error('Error sending message');
                }
                setLoading(false);
            });
        }
    } catch (err) {
        setLoading(false);
        errorHandle(err);
    }
};
