import supHttps from '../init/supHttps';

//SUP-INFO-01 : Get all the data required for support
export async function supInfoFun(type, phoneNumber) {
    const sup = await supHttps.get(`/support/search/${type}/${phoneNumber}`);
    return sup;
}

//SUP-INFO-02 : Create Ticket
export async function createTicketFun(type, data) {
    const sup = await supHttps.post(`/support/ticket/create/${type}`, data);
    return sup;
}

//SUP-INFO-04 : Get all the tickets
export async function ticketsInfo() {
    const tickets = await supHttps.get('/support/tickets');
    return tickets;
}
