import { useState, useEffect } from "react";
import "./bannerCarousel.css";
import { ReactComponent as RightArrow } from "../../assets/icons/trvto_arrowRightWhite.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/trvto_arrowLeftWhite.svg";

export default function BannerCarousel({ imageData, className = '' }) {

  const [bannerCarouselIndex, setBannerCarouselIndex] = useState(0);

  //Banner auto image slide every 2.5 sec
  useEffect(() => {
    const next = (bannerCarouselIndex + 1) % imageData.length;
    const id = setTimeout(() => setBannerCarouselIndex(next), 2500);
    return function () {
      clearTimeout(id);
    };
  }, [bannerCarouselIndex]);

  //Banner carousel previous button onClick
  function bannerCarouselPrev() {
    console.log(bannerCarouselIndex, imageData.length);
    setBannerCarouselIndex(
      bannerCarouselIndex === 0 ? imageData.length - 1 : bannerCarouselIndex - 1
    );
  }

  //Banner carousel next button onClick
  function bannerCarouselNext() {
    console.log(bannerCarouselIndex, imageData.length);
    setBannerCarouselIndex(
      bannerCarouselIndex + 1 === imageData.length ? 0 : bannerCarouselIndex + 1
    );
  }

  return (
    // <div className="bannerCarouselDiv">
    <div className={`bannerCarousel ${className}`}>
      {/* image of trip, a Carousel of the images is displayed */}
      <button className="bannerCarouselPrev" onClick={bannerCarouselPrev}>
        <LeftArrow className="bannerRightArrow" />
      </button>{" "}
      {/* prev image */}
      {imageData.map(function (image, imageIndex) {
        return (
          <img
            // src={image.imgSrc}
            src={image.url}
            alt={image.alt}
            key={imageIndex}
            className={
              imageIndex === bannerCarouselIndex
                ? "bannerCarouselImg"
                : "bannerCarouselImgHidden"
            }
          />
        );
      })}
      <button onClick={bannerCarouselNext} className="bannerCarouselNext">
        <RightArrow className="bannerRightArrow" />
      </button>
      {/* next image */}
      <span className="bannerCarouselIndicators">
        {imageData.map(function (_, circleIndex) {
          return (
            <div
              key={circleIndex}
              className={
                bannerCarouselIndex === circleIndex
                  ? "bannerCarouselIndicator"
                  : "bannerCarouselIndicatorTrs"
              }
            ></div>
          );
        })}
      </span>
    </div>
    // </div>
  );
}
