import { blogContentFun } from "../../../api/blogAPI";
import errorHandle from "../../../utils/errorHandle";

export default async function fetchBlogDetailData(id, setBlogDetails, setLoading) {
    setLoading(true);
    try {
        const blogData = await blogContentFun(id);
        setBlogDetails(blogData.data[0]);
        setLoading(false);
    } catch (error) {
        errorHandle(error);
        setLoading(false);
    }
}
