import './tripDetails.css';
import DetailsImageDisplay from '../../components/detailsImageDisplay/DetailsImageDisplay';
import TripBookingTab from './tripBookingTab/TripBookingTab';
import DetailsTabs from '../../components/detailsTabs/DetailsTabs';
import { useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import fetchTripData from './tripDetailsFun/fetchTripData';
import ReviewStars from '../../components/reviewStars/ReviewStars';
// import AyurBookingTab from "../../components/ayurBookingTab/AyurBookingTab";
import { ReactComponent as Share } from '../../assets/icons/trvto_share.svg';
import { share } from './tripDetailsFun/tripDetailsShare';
import fetchTripCheck from './tripCheckFun/tripCheck';
import { userReview } from '../../api/userAPI';

export default function TripDetails() {
  const [packData, setPackData] = useState({});
  const [packDates, setPackDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(true);
  const [checkTrip, setCheckTrip] = useState(false);
  const [userRating, setUserRating] = useState(0);
  const [popupRating, setPopupRating] = useState(userRating);
  const [typeSel, setTypeSel] = useState('trip');
  const [comment, setComment] = useState("");
  const packId = useRef(window.location.pathname.split('/')[3]);
  const type = useRef(
    window.location.pathname.split('/')[1] === 'org'
      ? window.location.pathname.split('/')[2]
      : window.location.pathname.split('/')[1]
  );
  const [allowedDates, setAllowedDates] = useState([]);
  // const whichType = window.location.pathname.split("/")[1];

  useEffect(() => {
    fetchTripData(
      type.current,
      packId.current,
      setLoading,
      setPackData,
      setPackDates
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTripCheck(type.current, packId.current, setCheckTrip);
  }, []);

  useEffect(() => {
    if (packDates.length !== 0) {
      setAllowedDates(
        packDates.map(function (obj) {
          return moment(obj.startDate);
        })
      );
    }
  }, [packDates]);

  const handleSubmitRating = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const ratingData = {
      packageId: packId.current,
      userName: localStorage.getItem("name"),
      userId: localStorage.getItem("userId"),
      rating: popupRating,
      organizerId: packData.organizerId,
      comment
    };
    await userReview(typeSel, ratingData);
    setUserRating(popupRating);
  };

  const closePopup = () => setPopupVisible(false);

  return (
    <div>
      {loading && (
        <div className="loadingBackground">
          <ReactLoading
            type="spin"
            color="#006aff"
            className="loadingSpin"
          />
        </div>
      )}
      {Object.keys(packData).length !== 0 ? (
        <>
          <div className="tripDetailsHeadingDiv">
            <h1 className="detailsImageDisplayName">
              {packData?.title || 'N/A'}
              <Share onClick={(e) => share(e)} />
              <button className='shareButton' onClick={(e) => share(e)}>Share</button>
            </h1>
            <div>
              {/* {whichType === "camp" ? (
            <span className="tripDetailsSubDetails">City Name</span>
          ) : ( */}
              <div className='tripBriefInfo'>
                <span className="tripDetailsSubDetails">
                  {packData?.days + 'D/' + packData?.nights + 'N'}
                </span>
                {/* )} */}

                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails">
                  {packDates?.length !== 0 &&
                    packDates[0]?.startDate
                      .split('/')
                      .reverse()
                      .join('/')}{' '}
                  Onwards
                </span>
                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails">
                  {packData?.quickView?.startTime}
                </span>
                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails">
                  {packData?.quickView?.stay}
                </span>
                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails">
                  {packData?.quickView?.food}
                </span>
                <span className="tripDetailsSubDetails">
                  {packData?.quickView?.type}
                </span>
                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails">
                  {packData?.quickView?.difficulty}
                </span>
                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails">
                  {packData?.organizerName || 'N/A'}
                </span>
                <span className="tripDetailsDot"></span>
                <span className="tripDetailsSubDetails alignCenter">
                  <ReviewStars rating={packData?.rating || 0} />
                  <span>{packData?.rating || 0}</span>
                  <p style={{ margin: 0 }}>({packData?.reviewCount || 0} Reviews)</p>
                </span>
              </div>
            </div>
          </div>
          <div className="tripDetails">
            <DetailsImageDisplay imageData={packData.images} />
            <div className="tripDetailsTextBooking">
              <div className="tripDetailsText">
                <DetailsTabs tripDetailsData={packData} />
              </div>
              <div className="tripDetailsBooking">
                {/* {whichType === "trip" ? ( */}
                <TripBookingTab
                  // tripBookingClick={tripBookingClick}
                  packData={packData}
                  allowedDates={allowedDates}
                  packDates={packDates}
                  setLoading={setLoading}
                />
                {/* ) : whichType === "ayur" ? (
              <AyurBookingTab />
            ) : whichType === "guid" ? (
              "Guid"
            ) : whichType === "camp" ? (
              "Camp"
            ) : null} */}
              </div>
            </div>
            {!checkTrip?.data?.status && popupVisible && (
              <div className="ratingModal">
                <div className="modalContent">
                  <h3>Rate Your Previous Trip</h3>
                  <ReviewStars
                    rating={popupRating}
                    onRatingChange={(newRating) => setPopupRating(newRating)}
                    isInteractive
                  />
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Add your comments here..."
                    className="commentBox"
                  />
                  <div className="modalActions">
                    <button onClick={(e) => {
                      e.stopPropagation();
                      handleSubmitRating(e);
                    }} className="submitRating">
                      Submit
                    </button>
                    <button
                      onClick={closePopup}
                      className="closeModal">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <p>No Data</p>
      )}
    </div>
  );
}
