import { useContext, useState } from 'react';
import './carousel.css';
import { ReactComponent as LeftBlack } from '../../assets/icons/trvto_left-arrow.svg';
import { ReactComponent as RightBlack } from '../../assets/icons/trvto_right-arrow.svg';
import { UserAuthContext } from '../../context/UserAuthContext';
// import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from "react-icons/bs"

export default function Carousel({ customClass, imageData, TripCardClick, packId }) {
    const [carouselIndex, setCarouselIndex] = useState(0);
    const { isAuthenticated } = useContext(UserAuthContext);

    //Carousel previous button onClick
    function carouselPrev() {
        setCarouselIndex(
            carouselIndex === 0 ? imageData.length - 1 : carouselIndex - 1
        );
    }

    //Carousel next button onClick
    function carouselNext() {
        setCarouselIndex(
            carouselIndex + 1 === imageData.length ? 0 : carouselIndex + 1
        );
    }

    return (
        <div className={`carousel ${customClass}`}>
            {/* image of trip, a carousel of the images is displayed */}
            <button className="carouselPrev" onClick={carouselPrev}>
                <LeftBlack className="carouselRightArrow" />
            </button>
            {/* prev image */}
            {imageData.map(function (image, imageIndex) {
                return (
                    <img
                        src={image}
                        alt={image.alt}
                        key={imageIndex}
                        className={
                            imageIndex === carouselIndex
                                ? 'carouselImg'
                                : 'carouselImgHidden'
                        }
                        onClick={() => TripCardClick(packId)}
                    />
                );
            })}
            <button onClick={carouselNext} className="carouselNext">
                <RightBlack className="carouselRightArrow" />
            </button>
            {/* next image */}
            <span className="carouselIndicators">
                {imageData.map(function (_, circleIndex) {
                    return (
                        <div
                            key={circleIndex}
                            className={
                                carouselIndex === circleIndex
                                    ? 'carouselIndicator'
                                    : 'carouselIndicatorTrs'
                            }
                        ></div>
                    );
                })}
            </span>
        </div>
    );
}
