import './orgBilling.css';
import '../dashboard/dashboardTable/dashboardTable.css';

import { useState } from 'react';
import Pagination from '../../components/pagination/Pagination';
import tableData from '../../dummyData/billingTable.json';
import handleTextChange from '../../utils/handleTextChange';
import FilterIcon from '../../assets/icons/trvto_filter.svg';
import ReactLoading from 'react-loading';
import EmptyPage from '../../components/empty-page/emptyPage';

const OrgBilling = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedApproval, setSelectedApproval] = useState('');
    const tableHeader = [
        'Package Name',
        'Date',
        'Customer Name',
        'Email',
        'Price',
        'Amount',
        'Status',
        'Action'
    ];
    const [pageNumber, setPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const totalData = 70;

    const paginationClick = (number, offSetNumber) => {
        if (Math.ceil(totalData / 10) > 1) {
            setPageNumber(+number);
            setOffSet(+offSetNumber);
            // setStartUpLoad(true);
        }
    };

    return (
        <>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="billingContainer">
                <div className="alignCenter filter">
                    <input
                        placeholder="Search..."
                        name="orgSearch"
                        value={searchText}
                        className="orgBillSearch"
                        onChange={(sea) => handleTextChange(sea, setSearchText)}
                    />
                    {/* <button
                        className="filterBtn"
                    // onClick={() =>
                    //     setShowSidebar(!showSidebar)
                    // }
                    >
                        <img
                            alt="trvto_filter-icon"
                            src={FilterIcon}
                            className="filterIcon"
                        />
                        Filter
                    </button> */}
                    <div className="orgBookingFilters">
                        <select
                            name="type"
                            id="type"
                            className="select"
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="">Select Status</option>
                            <option value="generated">Generated</option>
                            <option value="completed">Completed</option>
                            <option value="not-eligible">Not Eligible</option>
                        </select>
                        <select
                            name="type"
                            id="type"
                            className="select"
                            onChange={(e) =>
                                setSelectedApproval(e.target.value)
                            }
                        >
                            <option value="">Select Approval</option>
                            <option value="approve">Approved</option>
                            <option value="not-approved">Not Approved</option>
                        </select>
                    </div>
                </div>
                {tableData?.length === 0 && !loading ? (
                    <EmptyPage />
                ) : (
                    <div className="tableContainer">
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.data.map((row, rowIndex) => {
                                    return (
                                        <tr
                                            key={rowIndex}
                                            className="tableBodyRow"
                                        //   onClick={() => setRowClickId(row._id)}
                                        >
                                            <td>{row.package_name}</td>
                                            <td>{row.date}</td>
                                            <td>{row.customer_name}</td>
                                            <td>{row.email}</td>
                                            <td>₹ {row.price}</td>
                                            <td>₹ {row.amount}</td>
                                            <td className={row.status}>
                                                {row.status}
                                            </td>
                                            <td>
                                                {/* <button className="actBtn payoutBtn noWidth">
                                                    Payout
                                                </button> */}
                                                <button className="actBtn payoutBtn noWidth">
                                                    Bill Generation
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                <Pagination
                    paginationClick={paginationClick}
                    numberOfRows={totalData}
                    offSet={offSet}
                    pageNumber={pageNumber}
                />
            </div>
        </>
    );
};

export default OrgBilling;
