import './orgTripStrap.css';

import { FormContent } from '../bookingConfirmationCard/bookingConfirmationFun';
import ReviewStars from '../reviewStars/ReviewStars';
import { getAmenities } from './tripStrapFun/amenities';
import image1 from '../../assets/images/trvto_tripDetails1.jpg';

export default function OrgTripStrap({ page, setNav, data, setSelectedPack }) {
    return (
        <div>
            {data?.map((data) => (
                <div
                    key={data._id}
                    className="org_tripStrap"
                    onClick={() => {
                        setNav(true);
                        setSelectedPack(data._id);
                    }}
                >
                    <div className="org_tripStrapContent">
                        <img
                            src={image1}
                            alt="trvto_trip"
                            className="org_tripStrapImage"
                        />
                        <div className="org_tripStrapInfoDiv">
                            <div className="org_tripStrapNameDiv alignCenter">
                                <div className="org_tripStrapInfo">
                                    <div className="org_tripStrapName">
                                        <p className="org_tripStrapName">
                                            {data?.title}{' '}
                                        </p>
                                        {data?.quickView?.type === 'Trek' && (
                                            <div className="org_typeInfo">
                                                {data?.quickView?.type}
                                            </div>
                                        )}
                                        <div
                                            className={`org_typeInfo ${data?.quickView?.difficulty}`}
                                        >
                                            {data?.quickView?.difficulty}
                                        </div>{' '}
                                        <div
                                            className={`org_typeInfo ${data?.status === 'Active'
                                                ? 'active'
                                                : ''
                                                }`}
                                        >
                                            {data?.status}
                                        </div>
                                    </div>
                                    <div className="org_tripCardNumInfo">
                                        <ReviewStars rating={data?.rating} />
                                        <span>{data?.rating}</span>
                                        <p style={{ margin: 0 }}>
                                            ({data?.reviewCount} Reviews)
                                        </p>
                                    </div>
                                    <div className="org_tripStrapDateDetails">
                                        {/* <div className="alignCenter">
                                            <img
                                                src={Calendar}
                                                alt="calendar"
                                            />
                                            <span>
                                                {data?.quickView?.startDate} -{' '}
                                                {data?.quickView?.endDate}
                                            </span>
                                        </div> */}
                                        {/* <div className="divider" /> */}
                                        <div className="alignCenter">
                                            {/* <img src={UserCount} alt="user" /> */}
                                            <span>
                                                {data?.days} D, {data?.nights} N
                                            </span>
                                        </div>
                                    </div>

                                    {data.type === 'rooms' && (
                                        <div className="org_amenities">
                                            {getAmenities(
                                                data?.quickView?.amenities
                                            )}
                                        </div>
                                    )}
                                    {data.quickView?.type === 'Trek' && (
                                        <>
                                            <div className="org_innerFormContent">
                                                <FormContent
                                                    id="org_extraStyle"
                                                    label="Start Time"
                                                    answer={
                                                        data?.quickView
                                                            ?.startTime
                                                    }
                                                    className="borderNone width30"
                                                />
                                                <FormContent
                                                    id="org_extraStyle"
                                                    label="End Time"
                                                    answer={
                                                        data?.quickView?.endTime
                                                    }
                                                    className="borderNone width30"
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="org_tripStrapPriceDiv">
                                    <div className="org_tripStrapPrDiv">
                                        <p className="org_tripStrapPrice">
                                            ₹ {data?.price[0]?.price}
                                            <span>/person</span>
                                        </p>
                                        {/* {page === 'bookings' && (
                                            <button
                                                className="cancelBooking"
                                                onClick={handleCancelBooking}
                                            >
                                                Cancel Booking
                                            </button>
                                        )} */}
                                    </div>
                                    {/* <p className="tripStrapName">Share</p> */}
                                    {/* {page === 'wishlist' && (
                                <div>
                                    <button>Change Date</button>
                                    <button>Cancel</button>
                                    <p className="tripStrapName">
                                        Rate the Trip
                                    </p>
                                </div>
                            )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {data.quickView?.type === 'Trek' && (
                            <>
                                <div className="org_tripDivider org_holidayDivider" />
                                <p className="org_locationInfo">
                                    Pickup Cities:{' '}
                                    <span>{data?.quickView?.baseCities}</span>
                                </p>
                            </>
                        )}
                    </div>
                    {/* {showModal && <CancelBooking setShowModal={setShowModal} />} */}
                </div>
            ))}
        </div>
    );
}
