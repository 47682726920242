import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/home/Home';
import SerachResult from '../pages/searchResult/SearchResult';
import MyBooking from '../pages/myBookings/MyBookings';
import Blog from '../pages/blog/Blog';
import BlogDetails from '../pages/blogDeatils/BlogDetails';
import ContactUs from '../pages/contactUs/ContactUs';
import MyWishlist from '../pages/myWishlist/MyWishlist';
// import ResetPassword from "../components/login/resetPassword/ResetPassword";
import TripDetails from '../pages/tripDetails/TripDetails';
import AyurDetails from '../pages/ayurDetails/AyurDetails';
import GuidDetails from '../pages/guidDetails/GuidDetails';
import CampDetails from '../pages/campDetails/CampDetails';
import ReviewBooking from '../pages/reviewBooking/ReviewBooking';
import { UserLoginSecProvider } from '../context/UserLoginSecContext';
import { UserAuthProvider } from '../context/UserAuthContext';
import Header from '../webPageSections/header/Header';
import Footer from '../webPageSections/footer/Footer';
import { BookingDataProvider } from '../context/BookingDataContext';
import Pay from '../pages/pay/Pay';
import OrgAccount from '../pages/orgAccount/OrgAccount';
import Account from '../pages/account/Account';
import BookingConfirmation from '../pages/bookingConfirmation/bookingConfirmation';
import AboutUs from '../pages/aboutUs/AboutUs';
import Terms from '../pages/terms/Terms';
import Privacy from '../pages/privacy/Privacy';
import Cancellation from '../pages/cancellation/Cancellation';
import Faq from '../pages/faq/Faq';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

// let useri = 0;
// let timeStampExpire;
// let expiryTime;

export default function UserLayout() {
    //   if ((window.location.path = "/admin/")) {
    //     return <Navigate to="/admin/home" />;
    //   }

    // timeStampExpire = window.localStorage.getItem('expiry');
    // if (window.location.pathname !== '/' && useri === 0) {
    //     expiryTime = timeStampExpire - Date.now();
    //     console.log(expiryTime, useri);
    //     useri = 1;
    //     if (expiryTime < 0) {
    //         toast.error('Session has expired. Login again to continue...');
    //         setTimeout(() => {
    //             window.localStorage.clear();
    //             window.location = '/';
    //         }, 3000);
    //     } else {
    //         setTimeout(() => {
    //             toast.error('Session has expired. Login again to continue...');
    //             setTimeout(() => {
    //                 window.localStorage.clear();
    //                 window.location = '/';
    //             }, 3000);
    //         }, expiryTime);
    //     }
    // }

    return (
        <>
            <UserAuthProvider>
                <UserLoginSecProvider>
                    <BookingDataProvider>
                        <Header />

                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/home" element={<Home />} />
                            {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
                            <Route
                                path="/trip/details/:id"
                                element={<TripDetails />}
                            />
                            <Route
                                path="/ayur/details/:id"
                                element={<AyurDetails />}
                            />
                            <Route
                                path="/guid/details/:id"
                                element={<GuidDetails />}
                            />
                            <Route
                                path="/camp/details/:id"
                                element={<CampDetails />}
                            />
                            <Route
                                path="/search-result/:type/:text"
                                element={<SerachResult />}
                            />
                            <Route path="/my-booking" element={<MyBooking />} />
                            <Route
                                path="/my-wishlist"
                                element={<MyWishlist />}
                            />
                            <Route path="/blog" element={<Blog />} />
                            <Route
                                path="/blog/details/:id"
                                element={<BlogDetails />}
                            />
                            <Route
                                path="/review-booking"
                                element={<ReviewBooking />}
                            />
                            <Route path="/pay" element={<Pay />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/about-us" element={<AboutUs />} />
                            <Route path="/contact-us" element={<ContactUs />} />
                            <Route path="/terms" element={<Terms />} />
                            <Route
                                path="/privacy-policy"
                                element={<Privacy />}
                            />
                            <Route
                                path="/cancellation-policy"
                                element={<Cancellation />}
                            />
                            <Route path="/faq" element={<Faq />} />
                            <Route
                                path="/booking-confirmation"
                                element={<BookingConfirmation />}
                            />
                            <Route path="/*" element={<Navigate to="/" />} />
                            {/* <Route path="/" element={<Navigate to="/admin/home"/>} /> */}
                            {/* <Route path="/" element={<Home />} /> */}
                        </Routes>
                    </BookingDataProvider>
                </UserLoginSecProvider>
            </UserAuthProvider>
            <Footer />
        </>
    );
}
