import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase';

//upload the files to firebase and get the URL
export default async function fileUpload(file, type, which) {
    const orgId = localStorage.getItem('orgId') || 'org';
    let storageRef;

    if (which === 'create') {
        storageRef = ref(
            storage,
            process.env.REACT_APP_TRIPS_FILE_PATH /
                type /
                orgId /
                'package' /
                file.name
        );
    } else if (which === 'logo') {
        storageRef = ref(
            storage,
            process.env.REACT_APP_TRIPS_FILE_PATH /
                type /
                orgId /
                'logo' /
                file.name
        );
    }
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
}
