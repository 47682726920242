import ReviewCarousel from '../../../components/reviewCarousel/ReviewCarousel';
import { ReviewsData } from '../../../dummyData/reviewsData';
import ReviewBg from '../../../assets/home/trvto_reviewBg.png';
import './review.css';
import MobileCarousel from '../../../components/mobileCarousel/mobileCarousel';

const ReviewSection = () => {
    return (
        <div className="reviewSection customContainer">
            <div className="reviewWrapper">
                <div className="alignCenter mobCenter">
                    <div>
                        <p className='pageCount'>05 Reviews</p>
                        <p className="homeTitle">What They Are Say!</p>
                    </div>
                </div>
                <div className='mobHidden'>
                    <ReviewCarousel data={ReviewsData} />
                    <img className="reviewBg" src={ReviewBg} alt='trvto_Review' />
                </div>
                <div className="mobDisplay">
                    <MobileCarousel type='review' data={ReviewsData} />
                </div>
            </div>
        </div>
    );
}

export default ReviewSection;
