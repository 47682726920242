import './contactUs.css';
import { ReactComponent as Contact } from '../../assets/images/trvto_contact.svg';
import { ReactComponent as Phone } from '../../assets/icons/trvto_phone.svg';
import { ReactComponent as Email } from '../../assets/icons/trvto_email.svg';
import { ReactComponent as Address } from '../../assets/icons/trvto_address.svg';
import TitleBar from '../../components/titleBar/titleBar';
import handleChange from '../../utils/handleChange';
import { useState } from 'react';
import emailValid from '../../utils/emailValid';
import { toast } from 'react-toastify';
import phoneValid from '../../utils/phoneValid';
import { contactUs } from '../../api/userAPI';

export default function ContactUs() {
    const [contactData, setContactData] = useState({
        name: '',
        emailId: '',
        phoneNumber: '',
        message: ''
    });

    const onSubmit = async (e) => {
        e.preventDefault();
        if (contactData.name.length < 2) {
            toast.error('Name should have minimum 2 characters');
            return;
        } else if (contactData.message.length < 15) {
            toast.error('Message should have minimum 15 characters');
            return;
        } else if (!emailValid(contactData.emailId)) {
            toast.error('Please enter a valid email address');
            return;
        } else if (!phoneValid(contactData.phoneNumber)) {
            toast.error('Please enter a valid phone number');
            return;
        } else if (!contactData.name || !contactData.message) {
            toast.error('Please fill all the fields');
            return;
        } else {
            await contactUs(contactData);
            toast.success('Our team will get in touch with you shortly!');
            setContactData({
                name: '',
                emailId: '',
                phoneNumber: '',
                message: '',
            });
        }
    };

    return (
        <>
            <TitleBar title="Contact us" className="customTitle" />
            <div className="customContainer">
                <div className="contact-container">
                    <div className="imgCon">
                        <Contact className="conatct-img" />
                    </div>
                    <div className="contact-details">
                        <div className="details-item">
                            <div className="icon-con">
                                <Phone />
                            </div>
                            <a href="tel:9740665900">9742229962</a>
                        </div>
                        <div className="details-item">
                            <div className="icon-con">
                                <Email />
                            </div>{' '}
                            <a href="mailto:mr.sky.kj@gmail.com">
                                sagarb.b0@gmail.com
                            </a>
                        </div>
                        <div className="details-item">
                            <div className="icon-con">
                                <Address />
                            </div>{' '}
                            <span>
                                Sagar Engineering Tech, 6th cross, 11th main,
                                Rajarajeshwari nagar, Bengaluru, Karnataka -
                                560098
                            </span>
                        </div>
                    </div>
                </div>
                <b className="hr"></b>
                <div className="conatact-form">
                    <div className="cardCon">
                        <form
                            className="contact-card"
                            onSubmit={(e) => onSubmit(e)}
                        >
                            <div className="regInputDiv">
                                <label>Name</label>
                                <input
                                    placeholder="Enter name"
                                    name="name"
                                    className="regInput"
                                    value={contactData.emailId}
                                    onChange={(e) =>
                                        handleChange(e, setContactData)
                                    }
                                />
                            </div>
                            <div className="regInputDiv">
                                <label>Email</label>
                                <input
                                    placeholder="Enter email"
                                    name="emailId"
                                    className="regInput"
                                    value={contactData.emailId}
                                    onChange={(e) =>
                                        handleChange(e, setContactData)
                                    }
                                />
                            </div>
                            <div className="regInputDiv">
                                <label>Phone Number</label>
                                <input
                                    placeholder="Enter phone number"
                                    name="phoneNumber"
                                    className="regInput"
                                    value={contactData.phoneNumber}
                                    onChange={(e) =>
                                        handleChange(e, setContactData)
                                    }
                                />
                            </div>
                            <div className="regInputDiv">
                                <label>Questions / Comments</label>
                                <textarea
                                    style={{
                                        resize: 'none',
                                        minHeight: '80px'
                                    }}
                                    placeholder="Please enter details"
                                    name="message"
                                    className="regInput"
                                    value={contactData.message}
                                    onChange={(e) =>
                                        handleChange(e, setContactData)
                                    }
                                />
                            </div>
                            <div className="regInputDiv">
                                <button>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
